import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { lastValueFrom, take } from 'rxjs';
import { AppResource } from 'src/app/app.resource';
import { PathType } from 'src/app/core/enums/path-type.enum';
import { QualiteOccupant, TypeHabitation } from 'src/app/core/models/form-state.model';
import { Question } from 'src/app/core/models/question.model';
import { cloneResumeStep } from 'src/app/core/models/resume-step.model';
import { Step } from 'src/app/core/models/step.model';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import * as fromHousing from '../../../core/state/housing';
import * as fromInfo from '../../../core/state/info';
import * as fromPath from '../../../core/state/path';

import { MatomoTracker } from 'ngx-matomo-client';
import { Stepper } from 'src/app/core/models/stepper.model';
import { State } from 'src/app/core/state/core.state';
import { MatomoConstants } from 'src/app/shared/constants/matomo.constants';
import {
  NUMBER_PIECES_PATH,
  OWNER_TENANT_PATH,
  ROOT_PATH
} from 'src/app/shared/constants/route.constants';
import { PopUpInfoHAVM } from 'src/app/shared/models/components/popupinfoVm';

@Component({
  selector: 'app-house-apartment',
  templateUrl: './house-apartment.component.html',
  styleUrls: ['./house-apartment.component.scss']
})
export class HouseApartmentComponent extends BaseComponent implements OnInit {
  mouseOveredFlat = false;
  mouseOveredHouse = false;
  mouseOveredOther = false;

  flatSelected = false;
  houseSelected = false;
  otherSelected = false;

  nextStepEnabled = false;

  override step: Step = {
    step: this.resource.header.stepHousing,
    stepNumber: 1,
    subStep: this.resource.header.subStepHouseApartment,
    subStepNumber: 2
  };

  override question: Question = {
    libelle: this.resource.question.houseApartment,
    popupInfo: undefined,
    popupInfoHA: new PopUpInfoHAVM(
      this.resource.infobulle.house1,
      this.resource.infobulle.house2,
      this.resource.infobulle.apartment1,
      this.resource.infobulle.apartment2,
      this.resource.infobulle.otherHA1,
      this.resource.infobulle.otherHA2
    )
  };

  public override currentPage = MatomoConstants.TYPE_HABITATION;

  displayModal: boolean = false;

  constructor(
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private router: Router
  ) {
    super(store, resources, tracker);
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.updateResumeStepVisibility(true);
    this.loadStateData();
  }

  private async loadStateData() {
    var housingData = await lastValueFrom(
      this.store.select(fromHousing.selectHousing).pipe(take(1))
    );

    switch (housingData.SaisieHabitation.TypeHabitation) {
      case TypeHabitation.Maison:
        this.houseSelected = true;
        break;
      case TypeHabitation.Appartement:
        this.flatSelected = true;
        break;
    }
  }

  onHouseSelected() {
    if (!this.houseSelected) this.houseSelected = !this.houseSelected;
    if (this.houseSelected) {
      this.otherSelected = false;
      this.flatSelected = false;
    }

    this.store.dispatch(
      fromHousing.patchHousingType({
        payload: { TypeHabitation: TypeHabitation.Maison }
      })
    );

    this.nextStep();
  }

  onFlatSelected() {
    if (!this.flatSelected) this.flatSelected = !this.flatSelected;
    if (this.flatSelected) {
      this.houseSelected = false;
      this.otherSelected = false;
    }
    this.store.dispatch(
      fromHousing.patchOccupantType({
        payload: { TypeHabitation: TypeHabitation.Appartement }
      })
    );

    this.nextStep();
  }

  onOtherSelected() {
    this.otherSelected = !this.otherSelected;
    if (this.otherSelected) {
      this.houseSelected = false;
      this.flatSelected = false;
    }

    this.store.dispatch(
      fromHousing.patchHousingType({
        payload: { TypeHabitation: TypeHabitation.Autre }
      })
    );
    this.displayModal = true;
    this.tracker.trackEvent(MatomoConstants.TYPE_HABITATION, MatomoConstants.HOUSING_OTHER);
    this.tracker.trackEvent(
      MatomoConstants.ARRET_PARCOURS,
      MatomoConstants.TYPE_HABITATION,
      MatomoConstants.HOUSING_OTHER
    );
  }

  async nextStep() {
    await this.updateResumeStep();

    var path = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));
    await this.updateStepper(path);

    if (path === PathType.HOUSING_SOLIDARITY) {
      this.store.dispatch(
        fromHousing.patchOccupantType({
          payload: { QualiteOccupant: QualiteOccupant.Locataire }
        })
      );

      this.router.navigate([`${ROOT_PATH}${NUMBER_PIECES_PATH}`]);
    } else {
      this.router.navigate([`${ROOT_PATH}${OWNER_TENANT_PATH}`]);
    }
  }

  cancel() {
    this.displayModal = false;
  }

  async updateStepper(path: string) {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.lodging
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.houseapartment
    );

    if (subtitleS) subtitleS.isValid = true;

    let subtitleN;
    if (path == PathType.HOUSING_SOLIDARITY) {
      subtitleN = title?.subtitles.find(
        (x: { url: string }) => x.url == this.resource.stepper.numberpieces
      );
    } else {
      subtitleN = title?.subtitles.find(
        (x: { url: string }) => x.url == this.resource.stepper.ownertenant
      );
    }

    if (subtitleN) {
      subtitleN.isActive = true;
      subtitleN.isVisible = true;
    }

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  async updateResumeStep() {
    const resumeStep = await lastValueFrom(
      this.store.select(fromInfo.selectCurrentResumeStep).pipe(take(1))
    );
    const tmpResumeStep = cloneResumeStep(resumeStep);

    const stepResumeStep = tmpResumeStep.steps?.find(
      x => x.id == this.resource.resumestep.houseapartment
    );
    let libelle = '';

    if (this.houseSelected) libelle = this.resource.resumestep.house;
    else if (this.flatSelected) libelle = this.resource.resumestep.apartment;
    else if (this.otherSelected) libelle = this.resource.resumestep.other;

    if (stepResumeStep && libelle != '') {
      stepResumeStep.step = libelle;
      stepResumeStep.isVisible = true;
    } else {
      tmpResumeStep.steps?.push({
        id: this.resource.resumestep.houseapartment,
        step: libelle,
        url: this.resource.resumestep.houseapartment,
        isVisible: true
      });
    }
    this.store.dispatch(fromInfo.updateResumeStep({ payload: tmpResumeStep }));
  }

  setClassActive(value: boolean): string {
    return value ? 'active' : 'inactive';
  }
}
