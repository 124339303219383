<div class="mb-10 md:grid md:grid-cols-2 md:gap-x-4">
  <ng-container
    *ngIf="
      formuleRecommandee === FormuleRecommandeeEnum.F1 &&
      (path === PathType.HOUSING || path === PathType.UNDEFINED)
    ">
    <ng-container *ngTemplateOutlet="iB_C_Template"></ng-container>
    <ng-container *ngTemplateOutlet="iB_CP_Template"></ng-container>
  </ng-container>

  <ng-container
    *ngIf="
      formuleRecommandee === FormuleRecommandeeEnum.F2 &&
      (path === PathType.HOUSING || path === PathType.UNDEFINED)
    ">
    <ng-container *ngTemplateOutlet="iB_CP_Template"></ng-container>
    <ng-container *ngTemplateOutlet="iB_C_Template"></ng-container>
  </ng-container>

  <ng-container *ngIf="path === PathType.HOUSING_PARIS || path === PathType.HOUSING_TOURCOING || path === PathType.HOUSING_LILLE">
    <ng-container *ngTemplateOutlet="iB_P_Template"></ng-container>
  </ng-container>

  <ng-container *ngIf="path === PathType.HOUSING_SOLIDARITY">
    <ng-container *ngTemplateOutlet="iB_S_Template"></ng-container>
  </ng-container>

  <ng-template #iB_CP_Template>
    <div
      *ngIf="iB_CP"
      class="containerPanel-R mb-5 flex flex-col"
      [ngClass]="{
        recommanded: formuleRecommandee === FormuleRecommandeeEnum.F2,
        'not-recommanded': formuleRecommandee !== FormuleRecommandeeEnum.F2,
        borderRecommanded: formuleRecommandee === FormuleRecommandeeEnum.F2
      }">
      <div class="panelCPlus panel-offer flex-0">
        <div *ngIf="formuleRecommandee === FormuleRecommandeeEnum.F2" class="mx-16 pt-9">
          <p class="title-R">{{ resource.rate.recommendedOffer }}</p>
        </div>

        <div class="panelCPlusLogo-QC mx-5 mt-4 mb-2"></div>
        <div class="ml-16">
          <p class="rateMonth">
            {{ rateVM.F2.rateF_Show }}
            <span class="textMonth">{{ resource.confirmquote.text1 }}</span>
          </p>
        </div>
      </div>

      <div
        class="containerTextSB-QC text-left space-y-5 flex-1"
        [ngClass]="{
          recommanded: formuleRecommandee === FormuleRecommandeeEnum.F2,
          'not-recommanded': formuleRecommandee !== FormuleRecommandeeEnum.F2,
          topNotRecommandedCPlus: formuleRecommandee !== FormuleRecommandeeEnum.F2
        }">
        <div class="flex mx-5">
          <p class="titlePanel-R">
            {{ resource.rate.benefitCPlus }}
          </p>
        </div>
        <app-insurance-benefit
          *ngFor="let insuranceBenefit of insuranceBenefitCPVms.topPart"
          [insuranceBenefitVm]="insuranceBenefit"></app-insurance-benefit>
        <div class="relative">
          <img [src]="resource.rate.buttonPlusColorPath" alt="promo" class="benefitsCPlusLogo" />
        </div>

        <div class="benefitsCPlusBottomPart py-1">
          <app-insurance-benefit
            *ngFor="let insuranceBenefit of insuranceBenefitCPVms.bottomPart"
            [insuranceBenefitVm]="insuranceBenefit"></app-insurance-benefit>
        </div>
      </div>
      <div class="space-y-5 mx-5 mt-5 pb-7">
        <div class="text-center pt-3 pb-1">
          <p class="detailsBenefit">
            <a style="cursor: pointer" (click)="goToLink(rateVM.F2.F_Url)">{{
              resource.rate.detailBenefit
            }}</a>
          </p>
        </div>

        <button
          pButton
          class="mt-auto button-NS width-max iconSideLabel"
          [label]="resource.nextstep.chooseYourOfferC"
          (click)="offerSelected(typeOfferEnum.CP)"
          iconPos="right"
          [icon]="resource.rate.buttonPlusPath"
          (mouseover)="onMouseOver()"
          (mouseout)="onMouseOut()">
          <img [src]="imgSrc" style="position: absolute; right: 0.7rem; top: 1.1rem" />
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template #iB_C_Template>
    <div
      *ngIf="iB_C"
      class="containerPanel-R mb-5 flex flex-col"
      [ngClass]="{
        recommanded: formuleRecommandee === FormuleRecommandeeEnum.F1,
        'not-recommanded': formuleRecommandee !== FormuleRecommandeeEnum.F1,
        borderRecommanded: formuleRecommandee === FormuleRecommandeeEnum.F1
      }">
      <div class="panelC panel-offer flex-0">
        <div class="mx-16 pt-9" *ngIf="formuleRecommandee === FormuleRecommandeeEnum.F1">
          <p class="title-R">{{ resource.rate.recommendedOffer }}</p>
        </div>
        <div class="panelCLogo-QC mx-5 mt-4 mb-2"></div>
        <div class="ml-16">
          <p class="rateMonthC">
            {{ rateVM.F1.rateF_Show }}
            <span class="textMonthC"> {{ resource.confirmquote.text1 }}</span>
          </p>
        </div>
      </div>

      <div
        class="containerTextSB-QC text-left space-y-5 flex-1"
        [ngClass]="{
          recommanded: formuleRecommandee === FormuleRecommandeeEnum.F1,
          'not-recommanded': formuleRecommandee !== FormuleRecommandeeEnum.F1,
          topNotRecommandedC: formuleRecommandee !== FormuleRecommandeeEnum.F1
        }">
        <div class="flex mx-5">
          <p class="titlePanel-R">
            {{ resource.rate.benefitC }}
          </p>
        </div>

        <app-insurance-benefit
          *ngFor="let insuranceBenefit of insuranceBenefitCVms.topPart"
          [insuranceBenefitVm]="insuranceBenefit"></app-insurance-benefit>
        <div class="py-1">
          <app-insurance-benefit
            *ngFor="let insuranceBenefit of insuranceBenefitCVms.bottomPart"
            [insuranceBenefitVm]="insuranceBenefit"></app-insurance-benefit>
        </div>
      </div>

      <div class="space-y-5 mx-5 mt-5 pb-7">
        <div class="text-center pt-3 pb-1">
          <p class="detailsBenefit">
            <a style="cursor: pointer" (click)="goToLink(rateVM.F1.F_Url)">{{
              resource.rate.detailBenefit
            }}</a>
          </p>
        </div>

        <button
          pButton
          class="mt-auto button-NS width-max iconSideLabel"
          [label]="resource.nextstep.chooseYourOfferC"
          (click)="offerSelected(typeOfferEnum.C)"></button>
      </div>
    </div>
  </ng-template>

  <ng-template #iB_S_Template>
    <div class="containerPanel-R mb-5 mx-auto md:col-span-2">
      <div class="panelCPS-R pt-4">
        <div class="panelCSLogo-R mx-6 mt-4 mb-2"></div>
        <div class="mx-16">
          <p class="rateMonth">
            {{ rateVM.F1.rateF_Show }}
            <span class="textMonth"> {{ resource.confirmquote.text1 }}</span>
          </p>
        </div>
      </div>

      <div class="containerTextSB-QC text-left mx-6 space-y-5 mt-7 mb-5 pb-6">
        <div class="flex">
          <p class="titlePanel-R">
            {{ resource.rate.benefitS }}
          </p>
        </div>
        <div class="flex" style="margin-top: 0.5rem">
          <p class="subtitlePanel-R">
            {{ resource.rate.benefitBS }}
          </p>
        </div>

        <app-insurance-benefit
          *ngFor="let insuranceBenefit of insuranceBenefitSVms"
          [insuranceBenefitVm]="insuranceBenefit"></app-insurance-benefit>

        <div class="text-center pt-3 pb-1">
          <p class="detailsBenefit">
            <a style="cursor: pointer" (click)="goToLink(rateVM.F1.F_Url)">{{
              resource.rate.detailBenefit
            }}</a>
          </p>
        </div>

        <button
          pButton
          class="mx-auto button-NS width-max iconSideLabel"
          [label]="resource.nextstep.chooseYourOffer"
          (click)="offerSelected(typeOfferEnum.S)"></button>
      </div>
    </div>
  </ng-template>

  <ng-template #iB_P_Template>
    <div class="containerPanel-R mb-5 mx-auto md:col-span-2">
      <div class="panelCPS-R pt-4">
        <div [class]="productClass" class="mx-5 mt-4 mb-2"></div>
        <div class="mx-16">
          <p class="rateMonth">
            {{ rateVM.F1.rateF_Show }}
            <span class="textMonth"> {{ resource.confirmquote.text1 }}</span>
          </p>
        </div>
      </div>

      <div class="containerTextSB-QC text-left mx-6 space-y-5 mt-7 mb-5 pb-6">
        <div class="flex">
          <p class="titlePanel-R">
            {{ benefitM }}
          </p>
        </div>
        <div class="flex" style="margin-top: 0.5rem">
          <p class="subtitlePanel-R">
            {{ resource.rate.benefitBM }}
          </p>
        </div>

        <app-insurance-benefit
          *ngFor="let insuranceBenefit of insuranceBenefitMVms"
          [insuranceBenefitVm]="insuranceBenefit"></app-insurance-benefit>

        <div class="text-center pt-3 pb-1">
          <p class="detailsBenefit">
            <a style="cursor: pointer" (click)="goToLink(rateVM.F1.F_Url)">{{
              resource.rate.detailBenefit
            }}</a>
          </p>
        </div>

        <button
          pButton
          class="mx-auto button-NS width-max iconSideLabel"
          [label]="resource.nextstep.chooseYourOffer"
          (click)="offerSelected(typeOfferEnum.P)"></button>
      </div>
    </div>
  </ng-template>
</div>

<div class="container mt-3 mb-6 space-y-3" *ngIf="hasAnyPromotion && (brand !== 'HM' || isAdvisor)">
  <p class="promoCode">{{ resource.rate.questionPromo }}</p>

  <div class="flex space-x-3" [formGroup]="promoForm" *ngIf="promoForm">
    <input type="text" pInputText class="grow" formControlName="code" />
    <button
      pButton
      style="color: white; justify-content: space-between"
      class="flex button-NS"
      icon="pi pi-arrow-right"
      iconPos="right"
      [disabled]="code.errors && code.errors['required']"
      (click)="updateRate()"
      [loading]="isPromoLoading"></button>
  </div>
  <small class="p-error" *ngIf="code?.invalid && (code?.errors)!['promo']">{{
    PromotionCodeError
  }}</small>
  <div class="groupPromo flex space-x-6" *ngIf="prom">
    <div class="iconPromo py-2">
      <img [src]="resource.rate.couponPath" alt="promo" style="padding-top: 5px" />
    </div>
    <div>
      <p class="textBPromo">{{ promNoDescription ? resource.rate.codePromoOK : promString }}</p>
    </div>
    <div class="clearPromo">
      <button class="text-slate-400" (click)="updateRate()">X</button>
    </div>
  </div>
</div>
<app-error-popup
  [show]="displayError"
  (cancel)="displayError = false"
  [title]="resource.error.errorGenericTitle"
  [text]="resource.error.api"></app-error-popup>

