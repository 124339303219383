import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { MatomoTracker } from 'ngx-matomo-client';
import { lastValueFrom, take } from 'rxjs';
import { DownloadableFile } from 'src/app/api/models/document-blob';
import { HandlerRoleType, Handlers } from 'src/app/api/models/handler.model';
import { DevisDocumentType, QuotationDocumentType } from 'src/app/api/models/quotationDocumentType';
import { DocumentService } from 'src/app/api/services/document.service';
import { QuotationService } from 'src/app/api/services/quotation.service';
import { AppResource } from 'src/app/app.resource';
import { MimeType } from 'src/app/core/enums/mime-type.enum';
import { PathType } from 'src/app/core/enums/path-type.enum';
import { QuotationStates } from 'src/app/core/enums/quotation-state.enum';
import { Context } from 'src/app/core/models/context.model';
import {
  Devis,
  Formule,
  MontantValeurObjets2000,
  SaisieFormule,
  SaisiePersonnalisation,
  Selectionne
} from 'src/app/core/models/form-state.model';
import { Stepper } from 'src/app/core/models/stepper.model';
import * as fromContext from 'src/app/core/state/context';
import { State } from 'src/app/core/state/core.state';
import * as fromInfo from 'src/app/core/state/info';
import * as fromSubscriber from 'src/app/core/state/subscriber';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { ROOT_PATH, SERVICES_PATH } from 'src/app/shared/constants/route.constants';
import {
  getMetropoleBaseName,
  getMetropoleClassThree
} from 'src/app/shared/helpers/metropole-helper.service';
import { HeaderVM } from 'src/app/shared/models/components/headerVm';
import { ConfirmQuoteVM } from 'src/app/shared/models/components/quoteconfirmation';
import { OfferCustomizationEnum } from 'src/app/shared/models/enum/offercustomization.enum';
import { environment } from 'src/environments/environment';
import { String } from 'typescript-string-operations';
import * as fromAddress from '../../../core/state/address';
import * as fromDevis from '../../../core/state/devis';
import * as fromOfferCustomization from '../../../core/state/offer-customization';
import * as fromPath from '../../../core/state/path';
import * as fromTarification from '../../../core/state/tarification';

export interface option {
  id: string;
  name: string;
  valueShow: string | null;
  value: number;
}

@Component({
  selector: 'app-quote-confirmation',
  templateUrl: './quote-confirmation.component.html',
  styleUrls: ['./quote-confirmation.component.scss']
})
export class QuoteConfirmationComponent extends BaseComponent implements OnInit {
  headerStep!: HeaderVM;
  text!: any;
  productClass!: string;
  promo: boolean = false;
  codePromo: string = '';
  baseOption!: string;

  confirmQuoteVm!: ConfirmQuoteVM;
  options: option[] = [];

  iB_CP: boolean = false;
  iB_C: boolean = false;
  iB_P: boolean = false;
  iB_S: boolean = false;

  displayGdaModal: boolean = false;
  displayContractNotEligibleModal: boolean = false;
  displayContextErrorModal: boolean = false;
  disableNextStep: boolean = false;
  backgroundPanelPath!: string;
  textPanelPath!: string;
  path!: PathType;
  handlers: Handlers = [];
  offer!: SaisieFormule;
  customization!: SaisiePersonnalisation;
  devis!: Devis;
  context!: Context;
  devisName!: string;
  devisFileContent!: string;
  subscriber!: fromSubscriber.SubscriberState;
  address!: fromAddress.AddressState;

  contractNotEligibleModalText: string = '';
  constructor(
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private router: Router,
    private currencyPipe: CurrencyPipe,
    private documentService: DocumentService,
    private quotationService: QuotationService
  ) {
    super(store, resources, tracker);

    this.headerStep = new HeaderVM(
      this.resource.header.stepQuote,
      2,
      this.resource.header.subStepConfirmation,
      10
    );
  }

  override async ngOnInit() {
    super.ngOnInit();

    await this.LoadVm();

    if (this.context && this.context.contextId) {
      await this.initRateBase();

      this.setOption();

      this.loadStateData();

      this.updateRate();
    } else {
      this.displayContextErrorModal = true;
    }
  }

  get brand() {
    return environment.brand;
  }

  async LoadVm() {
    this.devis = await lastValueFrom(this.store.select(fromDevis.selectDevisState).pipe(take(1)));
    this.context = await lastValueFrom(this.store.select(fromContext.selectContext).pipe(take(1)));
    if (this.devis.DevisStatus == QuotationStates.Study) {
      this.displayGdaModal = true;
      this.disableNextStep = true;
    }

    if (
      this.devis &&
      !(
        this.context &&
        this.context.contextId &&
        this.context.contextData &&
        this.context.contextData.referenceDevis
      )
    ) {
      this.confirmQuoteVm = new ConfirmQuoteVM(
        this.devis.NumeroDevis,
        String.format(this.resource.confirmquote.subtitle, this.devis.NumeroDevis),
        this.options,
        this.devis.AgreementId,
        this.devis.DocumentDevis,
        this.devis.DocumentFIC,
        this.devis.DocumentInfo,
        this.devis.DocumentCG
      );
    } else if (
      this.context &&
      this.context.contextId &&
      this.context.contextData &&
      this.context.contextData.referenceDevis
    ) {
      this.confirmQuoteVm = new ConfirmQuoteVM(
        this.context.contextData.referenceDevis!,
        String.format(this.resource.confirmquote.subtitle, this.context.contextData.referenceDevis),
        this.options,
        this.devis.AgreementId,
        this.devis.DocumentDevis,
        this.devis.DocumentFIC,
        this.devis.DocumentInfo,
        this.devis.DocumentCG
      );
    }
  }

  async initRateBase() {
    this.path = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));
    this.productClass = getMetropoleClassThree(this.path) ?? '';

    this.handlers = await lastValueFrom(this.store.select(fromPath.selectHandlers).pipe(take(1)));

    this.offer = await lastValueFrom(
      this.store.select(fromOfferCustomization.selectSaisieFormule).pipe(take(1))
    );

    const promotion = await lastValueFrom(
      this.store.select(fromTarification.selectTarificationPromotion).pipe(take(1))
    );

    if (promotion?.CodePromoApplique === true) {
      this.promo = true;
      this.codePromo = promotion.CodePromo!;
    }

    this.customization = await lastValueFrom(
      this.store.select(fromOfferCustomization.selectSaisiePersonnalisation).pipe(take(1))
    );

    this.subscriber = await lastValueFrom(
      this.store.select(fromSubscriber.selectSubscriber).pipe(take(1))
    );

    this.address = await lastValueFrom(this.store.select(fromAddress.selectAddress).pipe(take(1)));

    switch (this.path) {
      case PathType.HOUSING_PARIS:
      case PathType.HOUSING_TOURCOING:
      case PathType.HOUSING_LILLE:
        this.iB_P = true;
        break;
      case PathType.HOUSING:
        if (this.offer.FormuleSelectionnee == Formule.F1) this.iB_C = true;
        if (this.offer.FormuleSelectionnee == Formule.F2) this.iB_CP = true;
        break;
      case PathType.HOUSING_SOLIDARITY:
        this.iB_S = true;
        break;
      default:
        this.iB_CP = true;
        break;
    }
  }

  setOption() {
    if (this.iB_CP) {
      if (this.promo)
        this.baseOption = String.format(
          this.resource.offercustomization.baseConfortPlusPromo,
          this.codePromo
        );
      else this.baseOption = this.resource.offercustomization.baseConfortPlus;
    } else if (this.iB_C) {
      if (this.promo)
        this.baseOption = String.format(
          this.resource.offercustomization.baseConfortPromo,
          this.codePromo
        );
      else this.baseOption = this.resource.offercustomization.baseConfort;
    } else if (this.iB_P) {
      const baseName = getMetropoleBaseName(this.path);
      if (baseName) {
        if (this.promo)
          this.baseOption = String.format(
            this.resource.offercustomization.baseMetropolePromo,
            baseName,
            this.codePromo
          );
        else
          this.baseOption = String.format(this.resource.offercustomization.baseMetropole, baseName);
      }
    } else if (this.iB_S) {
      if (this.promo)
        this.baseOption = String.format(
          this.resource.offercustomization.baseSolidairePromo,
          this.codePromo
        );
      else this.baseOption = this.resource.offercustomization.baseSolidaire;
    }
    this.options.push({
      id: OfferCustomizationEnum.Base,
      name: this.baseOption,
      valueShow: this.currencyPipe.transform(this.offer.ValeurMensuelle),
      value: this.offer.ValeurMensuelle
    });
  }

  downloadDoc(uri: string) {
    window.open(uri, '_blank');
  }

  private loadStateData() {
    if (this.customization.MontantValeurObjets2000 != null) {
      switch (this.customization.MontantValeurObjets2000) {
        case MontantValeurObjets2000.Montant5000:
          if (this.iB_CP) {
            this.options.push({
              id: OfferCustomizationEnum.Object,
              name: this.resource.offercustomization.option5k,
              valueShow: this.resource.offercustomization.rateInclude,
              value: 0
            });
          }
          break;
        case MontantValeurObjets2000.Montant10000:
          this.options.push({
            id: OfferCustomizationEnum.Object,
            name: this.resource.offercustomization.option10k,
            valueShow: this.currencyPipe.transform(
              this.devis.PrimesOptions?.ObjetsValeur?.Valeur10000!
            ),
            value: this.devis.PrimesOptions?.ObjetsValeur?.Valeur10000!
          });
          break;
        case MontantValeurObjets2000.Montant20000:
          this.options.push({
            id: OfferCustomizationEnum.Object,
            name: this.resource.offercustomization.option20k,
            valueShow: this.currencyPipe.transform(
              this.devis.PrimesOptions?.ObjetsValeur?.Valeur15000!
            ),
            value: this.devis.PrimesOptions?.ObjetsValeur?.Valeur15000!
          });
          break;
      }
    } else {
      if (this.iB_CP) {
        this.options.push({
          id: OfferCustomizationEnum.Object,
          name: this.resource.offercustomization.option5k,
          valueShow: this.resource.offercustomization.rateInclude,
          value: 0
        });
      }
    }

    if (this.customization.ProtectionVelos) {
      this.options.push({
        id: OfferCustomizationEnum.BP,
        name: this.resource.offercustomization.optionBP,
        valueShow: this.currencyPipe.transform(this.devis.PrimesOptions?.ProtectionVelos!),
        value: this.devis.PrimesOptions?.ProtectionVelos!
      });
    }

    if (this.customization.ProtectionJuridique) {
      this.options.push({
        id: OfferCustomizationEnum.JP,
        name: this.resource.offercustomization.optionJP,
        valueShow: this.currencyPipe.transform(this.devis.PrimesOptions?.ProtectionJuridique),
        value: this.devis.PrimesOptions?.ProtectionJuridique!
      });
    }

    if (this.customization.ProtectionNomades) {
      this.options.push({
        id: OfferCustomizationEnum.NP,
        name: this.resource.offercustomization.optionNP,
        valueShow: this.currencyPipe.transform(this.devis.PrimesOptions?.ProtectionNomades!),
        value: this.devis.PrimesOptions?.ProtectionNomades!
      });
    }

    if (this.customization.AssuranceScolaire != undefined) {
      const count = this.customization.AssuranceScolaire.length;
      const value = this.getRateSchoolInsurance(count);
      if (count > 0) {
        this.options.push({
          id: OfferCustomizationEnum.SI,
          name: this.resource.offercustomization.optionSI,
          valueShow: this.currencyPipe.transform(value),
          value: value
        });
      }
    }
  }

  updateRate() {
    this.confirmQuoteVm.rateMonth = this.currencyPipe.transform(this.devis.PrimeMensuelle);
    this.confirmQuoteVm.rateYear = this.currencyPipe.transform(this.devis.PrimeAnnuelle);
  }

  getRateSchoolInsurance(i: number): number {
    if (i == 1) return this.devis.PrimesOptions?.AssuranceScolaire?.Enfant1!;
    else if (i == 2) return this.devis.PrimesOptions?.AssuranceScolaire?.Enfant2!;
    else if (i == 3) return this.devis.PrimesOptions?.AssuranceScolaire?.Enfant3!;
    else if (i >= 4) return this.devis.PrimesOptions?.AssuranceScolaire?.Enfant4Plus!;
    else return 0;
  }

  nextStep() {
    if (this.devis.DevisStatus === QuotationStates.Converted) {
      this.displayContextErrorModal = true;
      this.disableNextStep = true;
    } else if (this.devis.AgreementId != null) {
      this.quotationService
        .GetQuotationContractEligibility(this.devis.AgreementId)
        .subscribe(resp => {
          if (!resp.isContractEligible) {
            this.buildContractNotEligibleModalText();
            this.displayContractNotEligibleModal = true;
            this.disableNextStep = true;
          } else {
            this.router.navigate([`${ROOT_PATH}${SERVICES_PATH}`]);
          }
        });
    }
  }

  validGDA() {
    this.displayGdaModal = false;
  }

  buildContractNotEligibleModalText() {
    let address = '';
    const addrSeparator = ', ';
    switch (this.address.adresseType) {
      case Selectionne.Selection: {
        address = this.address.addresseSelection?.Adresse!;
        break;
      }
      case Selectionne.Manuel: {
        address =
          this.address.addresseManuelle?.NumeroEtRue! +
          addrSeparator +
          this.address.addresseManuelle?.CodePostal! +
          addrSeparator +
          this.address.addresseManuelle?.Ville +
          addrSeparator +
          this.address.addresseManuelle?.Pays;
        break;
      }
      case Selectionne.Esh: {
        address =
          this.address.adresseEsh?.NumeroEtRue! +
          addrSeparator +
          this.address.adresseEsh?.CodePostal! +
          addrSeparator +
          this.address.adresseEsh?.Ville +
          addrSeparator +
          this.address.adresseEsh?.Pays;
        break;
      }
    }
    this.contractNotEligibleModalText = String.format(
      this.resource.confirmquote.textContractEligModal,
      this.subscriber.subscriber?.Email,
      address,
      this.handlers.find(x => x.roleType == HandlerRoleType.ContractManagement)?.phone
    );
  }

  async updateStepper() {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.quote
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.confirmquote
    );

    if (subtitleS) {
      subtitleS.isValid = true;
      subtitleS.isActive = true;
    }

    let titleN = tmpStepper.contract.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.contract
    );

    let subtitleNS = titleN?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.services
    );

    if (subtitleNS) subtitleNS.isActive = true;

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  DownloadDocument(documentType: QuotationDocumentType) {
    const file = this.getFileByDocumentType(documentType);
    if (!file) return;

    this.documentService
      .getAgreementDocument(
        Guid.parse(file.documentId),
        this.confirmQuoteVm.agreementId!,
        this.subscriber.subscriber?.Email!
      )
      .subscribe(data => {
        const blob = this.documentService.convertBase64ToBlob(
          atob(data.fileContents),
          MimeType.OCTET_STREAM
        );
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = data.fileDownloadName;
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  private getFileByDocumentType(documentType: QuotationDocumentType): DownloadableFile | undefined {
    switch (documentType) {
      case DevisDocumentType.DEV:
        return this.confirmQuoteVm.quoteDoc;
      case DevisDocumentType.FIC:
        return this.confirmQuoteVm.ficDoc;
      case DevisDocumentType.CG:
        return this.confirmQuoteVm.generalConditionDoc;
      case DevisDocumentType.DIPA:
        return this.confirmQuoteVm.productInformationDoc;
      default:
        return undefined;
    }
  }

  // Method to close the error dialog
  closeErrorModal() {
    this.displayContextErrorModal = false;
  }
}
