import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { lastValueFrom, take } from 'rxjs';
import { LessorList } from 'src/app/api/models/lessor.model';
import { LessorService } from 'src/app/api/services/lessor.service';
import { AppResource } from 'src/app/app.resource';
import { TypeBailleur } from 'src/app/core/models/form-state.model';
import { Stepper } from 'src/app/core/models/stepper.model';
import * as fromInfo from 'src/app/core/state/info';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { MatomoConstants } from 'src/app/shared/constants/matomo.constants';
import {
  CANCELLATION_PATH,
  PAYMENT_PATH,
  ROOT_PATH
} from 'src/app/shared/constants/route.constants';
import { HeaderVM } from 'src/app/shared/models/components/headerVm';
import { PopUpInfoVM } from 'src/app/shared/models/components/popupinfoVm';
import { QuestionVM } from 'src/app/shared/models/components/questionVm';
import { LessorCertificateEnum } from 'src/app/shared/models/enum/lessorcertificate.enum';
import { String } from 'typescript-string-operations';
import { State } from '../../../core/state/core.state';
import * as fromPath from '../../../core/state/path';
import * as fromService from '../../../core/state/service';

interface Lessor {
  text: string;
  value: number | string;
}

@Component({
  selector: 'app-lessor-certificate',
  templateUrl: './lessor-certificate.component.html',
  styleUrls: ['./lessor-certificate.component.scss']
})
export class LessorCertificateComponent extends BaseComponent implements OnInit {
  headerStep!: HeaderVM;
  override question!: QuestionVM;
  numberLocalInfo!: PopUpInfoVM;
  clientAccountInfo!: PopUpInfoVM;

  // State (Connaître la prochaine étape: Résiliation ou paiment)
  otherStepRoute: boolean = false;
  displayModal: boolean = false;
  msgShow: boolean = false;

  //Step
  step1Social: boolean = false;
  step2Social: boolean = false;

  //Form
  social: boolean = false;
  socialForm!: FormGroup;
  private: boolean = false;
  privateForm!: FormGroup;

  alreadyOccupied: boolean = false;
  notAlreadyOccupied: boolean = false;

  skip!: string;

  filterValue = '';
  selectedLessor!: Lessor | undefined;
  lessorCertificatEnum = LessorCertificateEnum;

  lessorNotFind: boolean = true;
  otherLessor: boolean = false;
  lessorsList: LessorList = [];
  private productCode!: string;

  placeholderRefClient!: string;
  errorRefClient!: string;

  placeholderCC!: string;
  errorCC!: string;

  get email() {
    return this.privateForm.get('email');
  }

  get lessor() {
    return this.socialForm.get('lessor');
  }

  get otherName() {
    return this.socialForm.get('otherName');
  }

  get otherEmail() {
    return this.socialForm.get('otherEmail');
  }

  get principalLocal() {
    return this.socialForm.get('principalLocal');
  }

  get clientAccount() {
    return this.socialForm.get('clientAccount');
  }

  get lessorList() {
    return [
      ...(this.lessorsList
        ? this.lessorsList?.map(b => ({
            text: b.name,
            value: b.id
          }))
        : []),
      { text: 'Autre', value: 'OTR' }
    ];
  }

  constructor(
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private router: Router,
    private lessorService: LessorService
  ) {
    super(store, resources, tracker);

    this.resource = resources['resource'];
    this.headerStep = new HeaderVM(
      this.resource.header.stepContract,
      3,
      this.resource.header.subStepLessorCertificate,
      4
    );

    this.question = new QuestionVM(this.resource.question.associatedService2);
    this.numberLocalInfo = new PopUpInfoVM(this.resource.infobulle.otherRC, '', '', '', '');

    this.clientAccountInfo = new PopUpInfoVM(this.resource.infobulle.otherCC, '', '', '', '');

    this.skip = this.resource.lessorcertificate.skipStep;
  }

  override async ngOnInit() {
    super.ngOnInit();

    this.initForm();
    this.productCode = await lastValueFrom(
      this.store.select(fromPath.selectPathType).pipe(take(1))
    );
    this.lessorService.getLessors(this.productCode).subscribe(response => {
      this.lessorsList = response;
    });

    this.lessor?.valueChanges.subscribe((value: any) => {
      if (value?.value === 'OTR') {
        this.otherName?.setValidators(Validators.required);
        this.otherEmail?.addValidators(Validators.required);
        this.otherEmail?.addValidators(
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
        );
        this.principalLocal?.clearValidators();
        this.clientAccount?.clearValidators();
        this.otherLessor = true;
        this.step1Social = false;
        this.step2Social = false;
      } else {
        this.otherName?.clearValidators();
        this.otherEmail?.clearValidators();
        this.principalLocal?.setValidators(Validators.required);
        this.clientAccount?.setValidators(Validators.required);
        this.otherLessor = false;
        this.lessorNotFind = true;
      }

      this.otherName?.updateValueAndValidity();
      this.otherEmail?.updateValueAndValidity();
      this.principalLocal?.updateValueAndValidity();
      this.clientAccount?.updateValueAndValidity();
    });

    this.otherName?.valueChanges.subscribe((value: string) => {
      let regex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
      let emailValid = regex.test(this.otherEmail?.value);
      if (emailValid && value != undefined && value != null && value != '')
        this.updateInfoLessor(value);
    });

    this.otherEmail?.valueChanges.subscribe((value: any) => {
      let regex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
      let emailValid = regex.test(value);
      if (emailValid && (this.otherName?.value != undefined || null || ''))
        this.updateInfoLessor(value);
    });

    await this.loadStateData();
  }

  private initForm() {
    this.socialForm = new FormGroup(
      {
        lessor: new FormControl<string | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        otherName: new FormControl<string | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        otherEmail: new FormControl<string | null>(null, {
          validators: [
            Validators.required,
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
          ],
          nonNullable: true
        }),
        principalLocal: new FormControl<string | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        clientAccount: new FormControl<string | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        })
      },
      {
        updateOn: 'change'
      }
    );

    this.privateForm = new FormGroup({
      email: new FormControl<string | null>('', {
        validators: [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
        ],
        nonNullable: true,
        updateOn: 'change'
      })
    });
  }

  private async loadStateData() {
    var service = await lastValueFrom(this.store.select(fromService.selectService).pipe(take(1)));

    if (service.saisieChoixServices.ResAncAss) this.otherStepRoute = true;

    if (service.saisieChoixServices.EnvoiAttestationBailleur?.TypeBailleur == TypeBailleur.Social) {
      this.social = true;

      this.selectedLessor = this.lessorList.find(obj => {
        return (
          obj.text ===
          service.saisieChoixServices.EnvoiAttestationBailleur?.BailleurSocial?.NomBailleur
        );
      });

      if (
        this.selectedLessor == undefined &&
        service.saisieChoixServices.EnvoiAttestationBailleur?.BailleurSocial?.NomBailleur
      ) {
        this.selectedLessor = this.lessorList.find(obj => {
          return obj.value === 'OTR';
        });

        this.otherName?.setValue(
          service.saisieChoixServices.EnvoiAttestationBailleur?.BailleurSocial?.NomBailleur!
        );

        this.otherEmail?.setValue(
          service.saisieChoixServices.EnvoiAttestationBailleur?.BailleurSocial?.Email!
        );

        this.updateInfoLessor(
          service.saisieChoixServices.EnvoiAttestationBailleur?.BailleurSocial?.NomBailleur!
        );
      } else if (this.selectedLessor != undefined) this.updateInfoLessor(this.selectedLessor.text);

      if (this.selectedLessor != undefined) {
        this.step1Social = true;
        this.step2Social = true;
      }

      this.principalLocal?.setValue(
        service.saisieChoixServices.EnvoiAttestationBailleur.BailleurSocial?.Reference1
      );

      this.clientAccount?.setValue(
        service.saisieChoixServices.EnvoiAttestationBailleur.BailleurSocial?.Reference2
      );
      if (service.saisieChoixServices.EnvoiAttestationBailleur.BailleurSocial != undefined) {
        if (service.saisieChoixServices.EnvoiAttestationBailleur.BailleurSocial.LogementOccupe)
          this.alreadyOccupied = true;
        if (!service.saisieChoixServices.EnvoiAttestationBailleur.BailleurSocial.LogementOccupe)
          this.notAlreadyOccupied = true;
      }
    } else if (
      service.saisieChoixServices.EnvoiAttestationBailleur?.TypeBailleur == TypeBailleur.Prive
    ) {
      this.private = true;

      this.email?.setValue(
        service.saisieChoixServices.EnvoiAttestationBailleur.BailleurPrive?.Email
      );
    }
  }

  nextStepType(value: string) {
    switch (value) {
      case TypeBailleur.Social:
        this.social = true;
        this.private = false;

        this.store.dispatch(
          fromService.patchService({
            payload: {
              EnvoiAttestationBailleur: {
                TypeBailleur: TypeBailleur.Social,
                isValid: false
              }
            }
          })
        );

        break;
      case TypeBailleur.Prive:
        this.private = true;
        this.social = false;

        this.store.dispatch(
          fromService.patchService({
            payload: {
              EnvoiAttestationBailleur: {
                TypeBailleur: TypeBailleur.Prive,
                isValid: false
              }
            }
          })
        );

        break;
      default:
        break;
    }
  }

  nextStepSocial(value: string) {
    switch (value) {
      case LessorCertificateEnum.Yes:
        this.alreadyOccupied = true;
        this.notAlreadyOccupied = false;
        this.step2Social = true;
        this.tracker.trackEvent(
          MatomoConstants.CONTENT_INTERACTION,
          MatomoConstants.NO_OCCUPATION,
          String.format(MatomoConstants.CHOIX_SERVICES_SP, 'Social')
        );
        break;
      case LessorCertificateEnum.No:
        this.notAlreadyOccupied = true;
        this.alreadyOccupied = false;
        this.step2Social = false;
        if (!this.msgShow) this.displayModal = true;
        break;
      default:
        break;
    }
  }

  nextStep(typeBailleur: string) {
    switch (typeBailleur) {
      case LessorCertificateEnum.Social:
        let lessorName =
          this.selectedLessor?.value! === 'OTR'
            ? this.otherName?.value!
            : this.selectedLessor?.text!;

        let lessorEmail = this.selectedLessor?.value! === 'OTR' ? this.otherEmail?.value! : '';

        this.store.dispatch(
          fromService.patchService({
            payload: {
              EnvoiAttestationBailleur: {
                TypeBailleur: TypeBailleur.Social,
                BailleurSocial: {
                  NomBailleur: lessorName,
                  Email: lessorEmail,
                  LogementOccupe: this.selectedLessor?.value! === 'OTR' ? null : this.step2Social,
                  Reference1: this.principalLocal?.value,
                  Reference2: this.clientAccount?.value
                },
                BailleurPrive: undefined,
                isValid: true
              }
            }
          })
        );
        break;
      case LessorCertificateEnum.Private:
        this.store.dispatch(
          fromService.patchService({
            payload: {
              EnvoiAttestationBailleur: {
                TypeBailleur: TypeBailleur.Prive,
                BailleurSocial: undefined,
                BailleurPrive: {
                  NomBailleur: null,
                  Email: this.email?.value
                },
                isValid: true
              }
            }
          })
        );
        break;
    }

    this.updateStepper(false);
    if (this.otherStepRoute) this.router.navigate([`${ROOT_PATH}${CANCELLATION_PATH}`]);
    else this.router.navigate([`${ROOT_PATH}${PAYMENT_PATH}`]);
  }

  skipStep() {
    let typeBailleur = undefined;
    if (this.social) typeBailleur = TypeBailleur.Social;
    else if (this.private) typeBailleur = TypeBailleur.Prive;

    this.tracker.trackEvent(
      MatomoConstants.CONTENT_INTERACTION,
      MatomoConstants.ACTIVATE_LATER,
      String.format(
        MatomoConstants.CHOIX_SERVICES_SP,
        typeBailleur === TypeBailleur.Prive ? 'Privé' : 'Social'
      )
    );
    if (typeBailleur != undefined) {
      if (typeBailleur == TypeBailleur.Social) {
        let lessorName =
          this.selectedLessor?.value! === 'OTR'
            ? this.otherEmail?.value!
            : this.selectedLessor?.text!;

        let lessorEmail = this.selectedLessor?.value! === 'OTR' ? this.otherEmail?.value! : '';
        this.store.dispatch(
          fromService.patchService({
            payload: {
              EnvAttB: false,
              EnvoiAttestationBailleur: {
                TypeBailleur: typeBailleur,
                BailleurSocial: {
                  NomBailleur: lessorName,
                  Email: lessorEmail,
                  LogementOccupe: this.selectedLessor?.value! === 'OTR' ? null : this.step2Social,
                  Reference1: this.principalLocal?.value,
                  Reference2: this.clientAccount?.value
                },
                BailleurPrive: undefined,
                isValid: false
              }
            }
          })
        );
      } else if (typeBailleur == TypeBailleur.Prive) {
        this.store.dispatch(
          fromService.patchService({
            payload: {
              EnvAttB: false,
              EnvoiAttestationBailleur: {
                TypeBailleur: typeBailleur,
                BailleurSocial: undefined,
                BailleurPrive: {
                  NomBailleur: null,
                  Email: this.email?.value
                },
                isValid: false
              }
            }
          })
        );
      }
    } else {
      this.store.dispatch(
        fromService.patchService({
          payload: {
            EnvAttB: false,
            EnvoiAttestationBailleur: {
              TypeBailleur: undefined,
              BailleurSocial: undefined,
              BailleurPrive: undefined,
              isValid: false
            }
          }
        })
      );
    }
    this.updateStepper(true);
    if (this.otherStepRoute) this.router.navigate([`${ROOT_PATH}${CANCELLATION_PATH}`]);
    else this.router.navigate([`${ROOT_PATH}${PAYMENT_PATH}`]);
  }

  async updateStepper(skip: boolean) {
    let service = await lastValueFrom(this.store.select(fromService.selectService).pipe(take(1)));
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.contract.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.contract
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.lessorcertificate
    );

    if (subtitleS) {
      if (!skip) {
        subtitleS.isValid = true;
        subtitleS.isActive = true;
      } else {
        subtitleS.isValid = false;
        subtitleS.isActive = false;
        subtitleS.isVisible = false;
      }
    }

    if (service.saisieChoixServices.ResAncAss) {
      let subtitleNS = title?.subtitles.find(
        (x: { url: string }) => x.url == this.resource.stepper.cancellation
      );

      if (subtitleNS) {
        subtitleNS.isActive = true;
      }
    } else {
      let titleN = tmpStepper.contract.titles.find(
        (x: { libelle: string }) => x.libelle == this.resource.stepper.sampling
      );

      if (titleN) titleN.isActive = true;

      let subtitleNS = titleN?.subtitles.find(
        (x: { url: string }) => x.url == this.resource.stepper.payment
      );

      if (subtitleNS) {
        subtitleNS.isActive = true;
      }
    }

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  confirmModal() {
    this.displayModal = false;
    this.msgShow = true;
    this.skip = this.resource.nextstep.skip;
  }

  onChangeLessor(selectedValue: any) {
    if (selectedValue.value.value != 'OTR') {
      this.step1Social = true;

      this.updateInfoLessor(selectedValue.value.text);
    } else this.step1Social = false;
  }

  updateInfoLessor(lessor: string) {
    switch (lessor) {
      case 'Elogie Siemp':
        this.placeholderRefClient = this.resource.lessorcertificate.elogieSiempRC;
        this.errorRefClient = this.resource.error.elogieSiempRC;
        this.placeholderCC = this.resource.lessorcertificate.elogieSiempCC;
        this.errorCC = this.resource.error.elogieSiempCC;

        this.numberLocalInfo.textOne = this.resource.infobulle.elogieSiempRC;
        this.numberLocalInfo.imgPath = this.resource.infobulle.elogieSiempRcPath;

        this.clientAccountInfo.textOne = this.resource.infobulle.elogieSiempCC;
        this.clientAccountInfo.imgPath = this.resource.infobulle.elogieSiempCcPath;
        break;
      case 'Paris Habitat':
        this.placeholderRefClient = this.resource.lessorcertificate.parisHabitatRC;
        this.errorRefClient = this.resource.error.parisHabitatRC;
        this.placeholderCC = this.resource.lessorcertificate.parisHabitatCC;
        this.errorCC = this.resource.error.parisHabitatCC;

        this.numberLocalInfo.textOne = this.resource.infobulle.parisHabitatRC;
        this.numberLocalInfo.imgPath = this.resource.infobulle.parisHabitatRcPath;

        this.clientAccountInfo.textOne = this.resource.infobulle.parisHabitatCC;
        this.clientAccountInfo.imgPath = this.resource.infobulle.parisHabitatCcPath;
        break;
      case 'RIVP':
        this.placeholderRefClient = this.resource.lessorcertificate.rivpRC;
        this.errorRefClient = this.resource.error.rivpRC;
        this.placeholderCC = this.resource.lessorcertificate.rivpCC;
        this.errorCC = this.resource.error.rivpCC;

        this.numberLocalInfo.textOne = this.resource.infobulle.rivpRC;
        this.numberLocalInfo.imgPath = this.resource.infobulle.rivpRcPath;

        this.clientAccountInfo.textOne = this.resource.infobulle.rivpCC;
        this.clientAccountInfo.imgPath = this.resource.infobulle.rivpCcPath;
        break;
      default:
        this.placeholderRefClient = this.resource.lessorcertificate.otherRC;
        this.errorRefClient = this.resource.error.otherRC;
        this.placeholderCC = this.resource.lessorcertificate.otherCC;
        this.errorCC = this.resource.error.otherCC;

        this.numberLocalInfo.textOne = this.resource.infobulle.otherRC;
        this.numberLocalInfo.imgPath = '';

        this.clientAccountInfo.textOne = this.resource.infobulle.otherCC;
        this.clientAccountInfo.imgPath = '';
        break;
    }
  }

  ToLowerCase(event: any) {
    let value = event.target.value;
    event.target.value = value.toLowerCase();
  }

  onNotFoundLessorClick() {
    this.selectedLessor = this.lessorList.find(obj => {
      return obj.value === 'OTR';
    });

    this.lessorNotFind = false;
  }

  setClassActive(value: boolean): string {
    return value ? 'active' : 'inactive';
  }
}
