import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { lastValueFrom, take } from 'rxjs';
import { AppResource } from 'src/app/app.resource';
import { Question } from 'src/app/core/models/question.model';
import { cloneResumeStep } from 'src/app/core/models/resume-step.model';
import { Step } from 'src/app/core/models/step.model';
import { State } from 'src/app/core/state/core.state';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import * as fromHousing from '../../../core/state/housing';
import * as fromInfo from '../../../core/state/info';
import { GenericEnum } from './../../../shared/models/enum/generic.enum';

import { MatomoTracker } from 'ngx-matomo-client';
import { Stepper } from 'src/app/core/models/stepper.model';
import { MatomoConstants } from 'src/app/shared/constants/matomo.constants';
import { DEPENDENCY_PATH, ROOT_PATH } from 'src/app/shared/constants/route.constants';
@Component({
  selector: 'app-previous-claim',
  templateUrl: './previous-claim.component.html',
  styleUrls: ['./previous-claim.component.scss']
})
export class PreviousClaimComponent extends BaseComponent implements OnInit {
  override step: Step = {
    step: this.resource.header.stepHousing,
    stepNumber: 1,
    subStep: this.resource.header.subStepPreviousClaim,
    subStepNumber: 7
  };

  override question: Question = {
    libelle: this.resource.question.previousClaim,
    popupInfo: undefined
  };

  public override currentPage = MatomoConstants.ANTECEDENTS_SINITRES;

  genericEnum = GenericEnum;

  //Form
  yes: boolean = false;
  no: boolean = false;

  displayModal: boolean = false;

  constructor(
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private router: Router
  ) {
    super(store, resources, tracker);
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.updateResumeStepVisibility(true);
    this.loadStateData();
  }

  private async loadStateData() {
    var housingData = await lastValueFrom(
      this.store.select(fromHousing.selectHousing).pipe(take(1))
    );

    if (
      housingData.SaisieHabitation.AntecedentSinistresInondables != null &&
      housingData.SaisieHabitation.AntecedentSinistresInondables != undefined
    ) {
      if (!housingData.SaisieHabitation.AntecedentSinistresInondables) this.no = true;
    }
  }

  async selectValue(value: string) {
    switch (value) {
      case GenericEnum.Yes:
        this.yes = true;
        this.no = false;
        this.tracker.trackEvent(
          MatomoConstants.TOO_MANY_ROOMS,
          MatomoConstants.ANTECEDENTS_SINITRES
        );
        this.tracker.trackEvent(MatomoConstants.ARRET_PARCOURS, MatomoConstants.INONDATIONS);
        this.store.dispatch(
          fromHousing.patchNumberOfPeople({ payload: { AntecedentSinistresInondables: true } })
        );
        this.displayModal = true;
        break;
      case GenericEnum.No:
        this.no = false;
        this.yes = true;
        this.store.dispatch(
          fromHousing.patchNumberOfPeople({ payload: { AntecedentSinistresInondables: false } })
        );

        await this.updateStepper();
        await this.updateResumeStep(this.resource.resumestep.inferiorThreeClaim);

        this.router.navigate([`${ROOT_PATH}${DEPENDENCY_PATH}`]);
        break;
    }
  }

  setClassActive(value: boolean): string {
    return value ? 'active' : 'inactive';
  }

  cancel() {
    this.displayModal = false;
  }

  async updateStepper() {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.lodging
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.previousclaim
    );

    if (subtitleS) subtitleS.isValid = true;

    let subtitleN = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.dependency
    );

    if (subtitleN) {
      subtitleN.isActive = true;
      subtitleN.isVisible = true;
    }

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  async updateResumeStep(libelle: string) {
    const resumeStep = await lastValueFrom(
      this.store.select(fromInfo.selectCurrentResumeStep).pipe(take(1))
    );
    const tmpResumeStep = cloneResumeStep(resumeStep);

    const stepResumeStep = tmpResumeStep.steps?.find(
      x => x.id == this.resource.resumestep.previousclaim
    );

    if (stepResumeStep) {
      stepResumeStep.step = libelle;
      stepResumeStep.isVisible = true;
    } else {
      tmpResumeStep.steps?.push({
        id: this.resource.resumestep.previousclaim,
        step: libelle,
        url: this.resource.resumestep.previousclaim,
        isVisible: true
      });
    }
    this.store.dispatch(fromInfo.updateResumeStep({ payload: tmpResumeStep }));
  }
}
