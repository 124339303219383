import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { Formule } from 'src/app/core/models/form-state.model';
import { getMetropolePackageCode } from 'src/app/shared/helpers/metropole-helper.service';
import { PackageCode } from 'src/app/shared/models/enum/packageCode.enum';
import { ReferenceDataService } from 'src/app/shared/services/reference-data-service';
import { Configuration } from '../configuration';
import { DependencyTypes } from '../models/priceRequest.model';
import { QuotationContractEligibilityResponse } from '../models/quotationContractEligibilityResponse.model';
import { Options, QuotationRequestModel, RiskItem } from '../models/quotationRequest.model';
import { QuotationResponseModel } from '../models/quotationResponse.model';
import { QuotationSummaryResponse } from '../models/quotationSummaryResponse.model';
import { VyVDevisCreateRequest } from '../models/vyVDevisCreateRequest';
import { VyVSharedCodeFormule } from '../models/vyVSharedCodeFormule';
import { VyVSharedProductCode } from '../models/vyVSharedProductCode';
import { VyVTarifCreateRequest } from '../models/vyVTarifCreateRequest';
import { DistributorService } from './distributor.service';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {
  public configuration = new Configuration();
  baseUrl: string;
  apiEndpoint: string = 'quotations';

  constructor(
    private http: HttpClient,
    configuration: Configuration,
    private refDataService: ReferenceDataService
  ) {
    this.baseUrl = configuration.apiBasePath ?? '';
  }

  public CreateQuotation(
    bodytarif: VyVTarifCreateRequest,
    bodyDevis: VyVDevisCreateRequest
  ): Observable<QuotationResponseModel> {
    const priceRequestModel = QuotationService.ToQuotationRequestModel(
      bodytarif,
      bodyDevis,
      this.refDataService
    );
    return this.http.post<QuotationResponseModel>(
      `${this.baseUrl}${this.apiEndpoint}`,
      priceRequestModel
    );
  }
  static ToQuotationRequestModel(
    bodytarif: VyVTarifCreateRequest,
    bodyDevis: VyVDevisCreateRequest,
    refDataService: ReferenceDataService
  ) {
    // Mapping de VyVTarifCreateRequest vers QuotationRequestModel :
    let riskItems: Array<RiskItem> = [];

    let address = bodyDevis.SaisieInfosSouscripteur.Adresse;
    address!.NumeroEtRue = address?.NumeroEtRue
      ? address?.NumeroEtRue
      : address?.NumeroDeRue + ' ' + address?.NomDeRue;

    let dependencyTypes: DependencyTypes | undefined;
    if (bodyDevis.SaisieHabitation.SurfaceDependance === 'MoinsDe50m2') {
      dependencyTypes = DependencyTypes._0_50m2;
    } else if (bodyDevis.SaisieHabitation.SurfaceDependance === 'De50a100m2') {
      dependencyTypes = DependencyTypes._50_100m2;
    } else if (bodyDevis.SaisieHabitation.SurfaceDependance === 'PlusDe100m2') {
      dependencyTypes = DependencyTypes._100_250m2;
    } else if (
      bodyDevis.SaisieHabitation.SurfaceDependance === null &&
      (bodyDevis.SaisieFormule.IdProduit == VyVSharedProductCode.HC ||
        bodyDevis.SaisieFormule.IdProduit == VyVSharedProductCode.HM_HC)
    ) {
      dependencyTypes = DependencyTypes._none;
    } else {
      dependencyTypes = undefined;
    }

    let civilite =
      bodyDevis.SaisieInfosSouscripteur.Civilite === 'Monsieur'
        ? 1
        : bodyDevis.SaisieInfosSouscripteur.Civilite === 'Madame'
        ? 2
        : 3;

    let options: Array<Options> = [];
    if (bodytarif.SaisiePersonnalisation?.AssuranceScolaire?.length ?? 0 > 0) {
      options.push({
        id: 17,
        code: 'RCS',
        metadatas: bodytarif.SaisiePersonnalisation?.AssuranceScolaire?.length.toString()
      });
    }
    if (bodytarif.SaisiePersonnalisation?.ProtectionVelos) {
      options.push({ id: 22, code: 'VELO' });
    }
    if (bodytarif.SaisiePersonnalisation?.ProtectionNomades) {
      options.push({ id: 24, code: 'ANO' });
    }
    if (bodytarif.SaisiePersonnalisation?.ProtectionJuridique) {
      if (
        bodyDevis.SaisieFormule.IdProduit == VyVSharedProductCode.HC ||
        bodyDevis.SaisieFormule.IdProduit == VyVSharedProductCode.HM_HC
      ) {
        options.push({ id: 16, code: 'PJ' });
      } else {
        options.push({ id: 25, code: 'PJSOL' });
      }
    }

    if (
      bodytarif.SaisiePersonnalisation?.MontantValeurObjets2000 === 'Montant10000' ||
      bodytarif.SaisiePersonnalisation?.MontantValeurObjets2000 === 'Montant15000'
    ) {
      options.push({
        id: 13,
        code: 'OBJVAL',
        metadatas:
          bodytarif.SaisiePersonnalisation?.MontantValeurObjets2000 === 'Montant10000'
            ? '_10K'
            : bodytarif.SaisiePersonnalisation?.MontantValeurObjets2000 === 'Montant15000'
            ? '_20K'
            : ''
      });
    }

    let housingRiskItem: RiskItem = {
      Order: 1,
      TypeCode: 'HAB',
      Name: 'Habitation',
      Fields: {
        ADR_COMP: '',
        ADR_RISQUE: address!.NumeroEtRue,
        ADR_CODE_POSTAL: address!.CodePostal,
        ADR_VILLE: address!.Ville,
        HAB_TYPE: bodyDevis.SaisieHabitation.TypeHabitation,
        NATURE_OCCU:
          bodyDevis.SaisieHabitation.QualiteOccupant === 'Locataire' ? 'Locataire' : 'Propriétaire',
        NATURE_LOG: bodyDevis.SaisieHabitation.NatureResidence,
        NBR_PIECES: bodyDevis.SaisieHabitation.NombrePieces
      }
    };

    housingRiskItem.Fields['ADR_ETAGE'] = bodyDevis.SaisieHabitation.Etage
      ? bodyDevis.SaisieHabitation.Etage === 'RezDeChaussee'
        ? 'Rez De Chaussée'
        : bodyDevis.SaisieHabitation.Etage === 'Intermediaire'
        ? 'Intermédiaire'
        : 'Dernier étage'
      : '';
    if (address.NomBailleur) {
      housingRiskItem.Fields['NOM_BAILLEUR'] = address.NomBailleur;
    }
    if (bodytarif.SaisieHabitation.CompositionFoyer) {
      housingRiskItem.Fields['NBR_PERS'] = bodytarif.SaisieHabitation.CompositionFoyer;
    }
    if (bodyDevis.SaisieHabitation.AvecVeranda !== null) {
      housingRiskItem.Fields['POSSEDE_VERANDA'] = bodyDevis.SaisieHabitation.AvecVeranda;
    }
    if (
      bodyDevis.SaisieHabitation.NombrePiecesSuperieures !== null &&
      (bodyDevis.SaisieFormule.IdProduit == VyVSharedProductCode.HC ||
        bodyDevis.SaisieFormule.IdProduit == VyVSharedProductCode.HM_HC)
    ) {
      housingRiskItem.Fields['NBR_PIECES_40M2'] =
        bodyDevis.SaisieHabitation.NombrePiecesSuperieures;
    }
    if (dependencyTypes) {
      housingRiskItem.Fields['SURFACE_DEP'] = dependencyTypes;
    }
    if (bodyDevis.SaisieHabitation.PiscineSpaJacuzzi !== null) {
      housingRiskItem.Fields['POSSEDE_PISCINE'] = bodyDevis.SaisieHabitation.PiscineSpaJacuzzi;
    }
    if (bodyDevis.SaisieHabitation.ObjetsValeurSuperieur2000 !== null) {
      housingRiskItem.Fields['OBJ_VAL_UNIT'] = bodyDevis.SaisieHabitation.ObjetsValeurSuperieur2000;
    }
    if (bodyDevis.SaisieHabitation.InstallationDD != null) {
      housingRiskItem.Fields['POSSEDE_ENERGIEV'] = bodyDevis.SaisieHabitation.InstallationDD;
    }
    if (
      bodyDevis.SaisieHabitation.PlafondMobilier &&
      (bodyDevis.SaisieFormule.IdProduit == VyVSharedProductCode.HC ||
        bodyDevis.SaisieFormule.IdProduit == VyVSharedProductCode.HM_HC)
    ) {
      var nbRooms =
        (bodyDevis.SaisieHabitation?.NombrePieces ?? 0) +
        (bodyDevis.SaisieHabitation?.NombrePiecesSuperieures ?? 0);
      var baremeValeurAssuree = refDataService.baremeValeurAssuree;
      var bareme = baremeValeurAssuree.find(x => x.NombrePieces == nbRooms)!;

      housingRiskItem.Fields['CAP_MOB_BESOIN'] =
        bodyDevis.SaisieHabitation.PlafondMobilier === 'F2' ? bareme.F2 : bareme.F1;
    }

    riskItems.push(housingRiskItem);

    bodytarif.SaisiePersonnalisation?.AssuranceScolaire?.forEach((enfant, index) => {
      let childRiskItem: RiskItem = {
        Order: index + 2,
        TypeCode: 'ENF',
        Name: 'Enfant',
        Fields: {
          NOM_ENFANT: enfant.Nom,
          PRENOM_ENFANT: enfant.Prenom,
          DATE_ENFANT: enfant.DateNaissance
        }
      };
      riskItems.push(childRiskItem);
    });

    if (bodytarif.SaisiePersonnalisation?.ProtectionJuridique) {
      let personRiskItem: RiskItem = {
        Order: riskItems.length + 1,
        TypeCode: 'PER',
        Name: 'Personne',
        Fields: {
          ADR_RISQUE: bodyDevis.SaisieInfosSouscripteur.Adresse?.NumeroEtRue,
          ADR_CODE_POSTAL: bodyDevis.SaisieInfosSouscripteur.Adresse?.CodePostal,
          ADR_VILLE: bodyDevis.SaisieInfosSouscripteur.Adresse?.Ville
        }
      };
      riskItems.push(personRiskItem);
    }

    const quotationRequestModel: QuotationRequestModel = {
      ContextId: bodyDevis.Context,
      PromotionCode: bodytarif.SaisieProduit.CodePromo ?? undefined,
      Subscriber: {
        CivilityTypeId: civilite,
        LastName: bodyDevis.SaisieInfosSouscripteur.Nom,
        FirstName: bodyDevis.SaisieInfosSouscripteur.Prenom,
        Email: bodyDevis.SaisieInfosSouscripteur.Email,
        // maybe we can add a phone prefix property in VyVDevisCreateRequest.SaisieInfosSouscripteur
        PhonePrefix: bodyDevis.SaisieInfosSouscripteur.Telephone?.split('-')[0] ?? '+33',
        CellPhone: bodyDevis.SaisieInfosSouscripteur.Telephone?.split('-')[1],
        BirthDate: bodyDevis.SaisieInfosSouscripteur.DateNaissance.split('T')[0],
        BirthCity: bodyDevis.SaisieInfosSouscripteur.VilleNaissance,
        BirthCountry: bodyDevis.SaisieInfosSouscripteur.PaysNaissance,
        ZipCode: address!.CodePostal,
        City: address!.Ville
      },
      PackageAndOptionalCoverage: {
        DepartmentCode: address!.CodePostal.substring(0, 2),
        ProductCode: bodyDevis.SaisieFormule.IdProduit,
        HasClaims: bodyDevis.SaisieHabitation.AntecedentSinistresInondables ? true : undefined,
        PackageCode: this.getPackageCode(
          bodyDevis.SaisieFormule.FormuleSelectionnee,
          bodyDevis.SaisieFormule.IdProduit
        ),
        RecommendedPackageCode: bodyDevis.SaisieFormule.IdProduit.includes('HC')
          ? this.getPackageCode(
              bodyDevis.SaisieFormule.FormuleRecommandee,
              bodyDevis.SaisieFormule.IdProduit
            )
          : null,
        Options: options,
        DistributorCode: DistributorService.GetDistributorCode()
      },
      RiskItems: riskItems
    };
    return quotationRequestModel;
  }

  static getPackageCode(formule: VyVSharedCodeFormule, idProduit: VyVSharedProductCode): string {
    if (formule === Formule.F1) {
      return PackageCode.CONF;
    } else if (formule === Formule.F2) {
      return PackageCode.CONF_PLUS;
    } else {
      const metropolePackageCode = getMetropolePackageCode(idProduit);
      if (metropolePackageCode) {
        return metropolePackageCode;
      } else {
        return PackageCode.SOLIDAIRE;
      }
    }
  }

  public GetQuotationContractEligibility(
    id: Guid
  ): Observable<QuotationContractEligibilityResponse> {
    return this.http.get<QuotationContractEligibilityResponse>(
      `${this.baseUrl}${this.apiEndpoint}` + '/' + id + '/contractEligibility'
    );
  }
  public GetQuotationSummary(
    reference: string,
    context: Guid,
    email: string
  ): Observable<QuotationSummaryResponse> {
    let filter = new HttpParams();

    if (context) {
      filter = filter.append('contextId', context.toString());
    }

    if (email) {
      filter = filter.append('email', email);
    }

    return this.http.get<QuotationSummaryResponse>(
      `${this.baseUrl}${this.apiEndpoint}/${reference}/summary`,
      {
        params: filter
      }
    );
  }
}
