import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { lastValueFrom, take } from 'rxjs';
import { AppResource } from 'src/app/app.resource';
import { Formule, SaisieHabitation } from 'src/app/core/models/form-state.model';
import { cloneResumeStep } from 'src/app/core/models/resume-step.model';
import { Step } from 'src/app/core/models/step.model';
import { Stepper } from 'src/app/core/models/stepper.model';
import { State } from 'src/app/core/state/core.state';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { MatomoConstants } from 'src/app/shared/constants/matomo.constants';
import { ROOT_PATH, SUBSCRIBER_PATH } from 'src/app/shared/constants/route.constants';
import { QuestionVM } from 'src/app/shared/models/components/questionVm';
import { InsuredValueEnum } from 'src/app/shared/models/enum/insuredvalue.enum';
import {
  BaremeValeurAssureeElement,
  ReferenceDataService
} from 'src/app/shared/services/reference-data-service';
import { environment } from 'src/environments/environment';
import { String } from 'typescript-string-operations';
import * as fromHousing from '../../../core/state/housing';
import * as fromInfo from '../../../core/state/info';
import * as fromOffer from '../../../core/state/offer-customization';

@Component({
  selector: 'app-insured-value',
  templateUrl: './insured-value.component.html',
  styleUrls: ['./insured-value.component.scss']
})
export class InsuredValueComponent extends BaseComponent implements OnInit {
  questionOne!: QuestionVM;
  questionTwo!: QuestionVM;

  displayModal: boolean = false;

  override step: Step = {
    step: this.brand === 'HM' ? this.resource.header.stepHousing : this.resource.header.stepCouncil,
    stepNumber: 1,
    subStep: this.resource.header.subStepInsuredValue,
    subStepNumber: 10
  };

  insuredValueEnum = InsuredValueEnum;

  //Form
  yes: boolean = false;
  no: boolean = false;
  minK: boolean = false;
  maxK: boolean = false;
  moreThanMaxK: boolean = false;
  nbRooms!: number;
  bareme!: BaremeValeurAssureeElement;
  labelMaxF1!: string;
  labelMaxF2!: string;
  labelMoreThanF2!: string;

  public override currentPage = MatomoConstants.OBJETS_VALEURS;

  get brand() {
    return environment.brand;
  }

  constructor(
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private router: Router,
    private refDataService: ReferenceDataService,
    private currencyPipe: CurrencyPipe
  ) {
    super(store, resources, tracker);

    this.questionOne = new QuestionVM(this.resource.question.council1);
    this.questionTwo = new QuestionVM(this.resource.question.council2);
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.updateResumeStepVisibility(true);
    this.loadStateData();
  }

  private async loadStateData() {
    this.store.select(fromHousing.selectHousing).subscribe(res => {
      this.nbRooms =
        (res.SaisieHabitation?.NombrePieces ?? 0) +
        (res.SaisieHabitation?.NombrePiecesSuperieures ?? 0);
      this.processMaxValue();
    });

    var housingData = await lastValueFrom(
      this.store.select(fromHousing.selectHousing).pipe(take(1))
    );

    if (housingData.SaisieHabitation.ObjetsValeurSuperieur2000 != undefined) {
      if (housingData.SaisieHabitation.ObjetsValeurSuperieur2000) this.yes = true;
      else this.no = true;
    }

    if (housingData.SaisieHabitation.PlafondMobilier != undefined) {
      if (this.bareme != undefined) {
        if (housingData.SaisieHabitation.PlafondMobilier == Formule.F1) this.minK = true;
        if (housingData.SaisieHabitation.PlafondMobilier == Formule.F2) this.maxK = true;
      }
    }
  }

  private processMaxValue() {
    var baremeValeurAssuree = this.refDataService.baremeValeurAssuree;
    this.bareme = baremeValeurAssuree.find(x => x.NombrePieces == this.nbRooms)!;
    this.labelMaxF1 = String.format(
      this.resource.insuredvalue.max,
      this.currencyPipe.transform(this.bareme.F1, 'EUR', 'symbol', '5.0-0')
    );
    this.labelMaxF2 = String.format(
      this.resource.insuredvalue.max,
      this.currencyPipe.transform(this.bareme.F2, 'EUR', 'symbol', '5.0-0')
    );
    this.labelMoreThanF2 = String.format(
      this.resource.insuredvalue.moreThanMax,
      this.currencyPipe.transform(this.bareme.F2, 'EUR', 'symbol', '5.0-0')
    );
  }

  async selectUnitValue(value: string) {
    switch (value) {
      case InsuredValueEnum.Yes:
        this.yes = true;
        this.no = false;
        var ObjetsValeurSuperieur2000 = true;
        this.store.dispatch(
          fromHousing.patchNumberOfPeople({
            payload: { ObjetsValeurSuperieur2000 } as Partial<SaisieHabitation>
          })
        );
        break;
      case InsuredValueEnum.No:
        this.yes = false;
        this.no = true;
        var ObjetsValeurSuperieur2000 = false;
        this.store.dispatch(
          fromHousing.patchNumberOfPeople({
            payload: { ObjetsValeurSuperieur2000 } as Partial<SaisieHabitation>
          })
        );
        break;
    }

    if (this.minK || this.maxK) {
      await this.setfromInfo();
      await this.updateStepper();
      this.dispatchState();
    }
  }

  async selectValueMax(value: string) {
    switch (value) {
      case InsuredValueEnum.MinK:
        this.minK = true;
        this.maxK = false;
        var PlafondMobilier = Formule.F1;
        this.store.dispatch(
          fromHousing.patchNumberOfPeople({
            payload: { PlafondMobilier } as Partial<SaisieHabitation>
          })
        );
        break;
      case InsuredValueEnum.MaxK:
        this.minK = false;
        this.maxK = true;
        var PlafondMobilier = Formule.F2;
        this.store.dispatch(
          fromHousing.patchNumberOfPeople({
            payload: { PlafondMobilier } as Partial<SaisieHabitation>
          })
        );
        break;
    }

    if (this.yes || this.no) {
      await this.setfromInfo();
      await this.dispatchState();
    }
  }

  onMoreThanMaxSelected() {
    this.minK = false;
    this.maxK = false;
    this.displayModal = true;
  }

  cancel() {
    this.displayModal = false;
  }

  async setfromInfo() {
    if (this.yes)
      await this.updateResumeStep(
        this.resource.resumestep.insuredvalue,
        this.resource.resumestep.vuSuperior
      );
    else
      await this.updateResumeStep(
        this.resource.resumestep.insuredvalue,
        this.resource.resumestep.vuInferior
      );

    if (this.minK)
      await this.updateResumeStep(
        this.resource.resumestep.insuredvaluemax,
        String.format(
          this.resource.resumestep.valueMaxBareme,
          this.currencyPipe.transform(this.bareme.F1, 'EUR', 'symbol', '5.0-0')
        )
      );
    else
      await this.updateResumeStep(
        this.resource.resumestep.insuredvaluemax,
        String.format(
          this.resource.resumestep.valueMaxBareme,
          this.currencyPipe.transform(this.bareme.F2, 'EUR', 'symbol', '5.0-0')
        )
      );
  }

  setClassActive(value: boolean): string {
    return value ? 'active' : 'inactive';
  }

  async dispatchState() {
    var FormuleRecommandee = Formule.F1;
    if (this.yes) {
      FormuleRecommandee = Formule.F2;
    } else if (this.no) {
      if (this.minK) {
        FormuleRecommandee = Formule.F1;
      } else if (this.maxK) {
        FormuleRecommandee = Formule.F2;
      }
    }

    this.store.dispatch(fromOffer.patchFormuleRecommandee({ FormuleRecommandee }));

    await this.updateStepper();
    this.router.navigate([`${ROOT_PATH}${SUBSCRIBER_PATH}`]);
  }

  async updateStepper() {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.lodging
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.insuredvalue
    );

    if (subtitleS) subtitleS.isValid = true;

    let titleN = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.offer
    );

    if (titleN) titleN.isActive = true;

    let subtitleNS = titleN?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.subscriber
    );

    if (subtitleNS) subtitleNS.isActive = true;

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  async updateResumeStep(id: string, libelle: string) {
    const resumeStep = await lastValueFrom(
      this.store.select(fromInfo.selectCurrentResumeStep).pipe(take(1))
    );

    const tmpResumeStep = cloneResumeStep(resumeStep);

    let stepResumeStep = tmpResumeStep.steps?.find(x => x.id == id);

    if (stepResumeStep) {
      stepResumeStep.step = libelle;
      stepResumeStep.isVisible = true;
    } else {
      tmpResumeStep.steps?.push({
        id: id,
        step: libelle,
        url: this.resource.resumestep.insuredvalue,
        isVisible: true
      });
    }
    this.store.dispatch(fromInfo.updateResumeStep({ payload: tmpResumeStep }));
  }
}
