import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { lastValueFrom, take } from 'rxjs';
import { option } from 'src/app/api/models/option.model';
import { ClientAdvisorService } from 'src/app/api/services/clientAdvisor.service';
import { QuotationService } from 'src/app/api/services/quotation.service';
import { AppResource } from 'src/app/app.resource';
import { PathType } from 'src/app/core/enums/path-type.enum';
import {
  Formule,
  FormuleApi,
  MontantValeurObjets2000,
  SaisieFormule,
  SaisieHabitation,
  SaisieInfosSouscripteur,
  SaisiePersonnalisation,
  Tarification
} from 'src/app/core/models/form-state.model';
import { Stepper } from 'src/app/core/models/stepper.model';
import * as fromAddress from 'src/app/core/state/address';
import { AddressState } from 'src/app/core/state/address';
import * as fromContext from 'src/app/core/state/context';
import { State } from 'src/app/core/state/core.state';
import * as fromHousing from 'src/app/core/state/housing';
import * as fromInfo from 'src/app/core/state/info';
import * as fromOfferCustomization from 'src/app/core/state/offer-customization';
import * as fromPath from 'src/app/core/state/path';
import * as fromSubscriber from 'src/app/core/state/subscriber';
import * as fromTarification from 'src/app/core/state/tarification';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { SCHOOL_INSURANCE_PATH } from 'src/app/shared/constants/route.constants';
import {
  getMetropoleBaseName,
  getMetropoleClassOne
} from 'src/app/shared/helpers/metropole-helper.service';
import { CustomizeOfferVM } from 'src/app/shared/models/components/customizeofferVm';
import { HeaderVM } from 'src/app/shared/models/components/headerVm';
import { QuestionVM } from 'src/app/shared/models/components/questionVm';
import { OfferCustomizationEnum } from 'src/app/shared/models/enum/offercustomization.enum';
import { QuotationSummaryService } from 'src/app/shared/services/quotation-summary.service';
import { ApiRequestBodyUtils } from 'src/app/shared/utils/apiRequestBodyUtils';
import { roundToMonth } from 'src/app/shared/utils/mathUtils';
import { environment } from 'src/environments/environment';
import { String } from 'typescript-string-operations';
import { PopUpInfoVM } from '../../../shared/models/components/popupinfoVm';

@Component({
  selector: 'app-offer-customization',
  templateUrl: './offer-customization.component.html',
  styleUrls: ['./offer-customization.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OfferCustomizationComponent extends BaseComponent implements OnInit {
  headerStep!: HeaderVM;
  override question!: QuestionVM;
  productClass!: string;

  iB_CP: boolean = false;
  iB_C: boolean = false;
  iB_P: boolean = false;
  iB_S: boolean = false;

  rate: number = 0;
  promo: boolean = false;
  codePromo: string = '';
  baseOption!: string;
  //Form
  equal5k: boolean = true;
  equal10k: boolean = false;
  equal20k: boolean = false;

  juridProtect: boolean = false;
  popupJP!: PopUpInfoVM;
  showBikeProtect: boolean = false;
  bikeProtect: boolean = false;
  popupBP!: PopUpInfoVM;
  showNomadeProtect: boolean = false;
  nomadeProtect: boolean = false;
  popupMP!: PopUpInfoVM;
  schoolInsurance: boolean = false;
  popupSI!: PopUpInfoVM;

  customizeOfferVm!: CustomizeOfferVM;
  options: option[] = [];

  context!: string;
  address!: AddressState;
  saisieHabitation!: SaisieHabitation;
  saisieInfosSouscripteur!: SaisieInfosSouscripteur;
  customizeOffer: boolean = false;
  offerCustomizationEnum = OfferCustomizationEnum;
  tarification!: Tarification;
  path!: PathType;
  currentFormule!: FormuleApi;
  offer!: SaisieFormule;
  formuleRecommandee!: Formule;
  customization!: SaisiePersonnalisation;

  isLoading: boolean = false;
  displayError: boolean = false;

  montantValeurObjets: MontantValeurObjets2000 | undefined;

  clientAdvisorFullName: string = '';

  get brand() {
    return environment.brand;
  }

  constructor(
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private router: Router,
    private currencyPipe: CurrencyPipe,
    private quotationService: QuotationService,
    private quotationSummaryService: QuotationSummaryService,
    private clientAdvisorService: ClientAdvisorService
  ) {
    super(store, resources, tracker);

    //#region Shared Components
    this.headerStep = new HeaderVM(
      this.brand === 'HM' ? this.resource.header.stepQuote : this.resource.header.stepOffer,
      2,
      this.resource.header.subStepCustomization,
      6
    );
    this.question = new QuestionVM(
      `${this.resource.question.customizationHeader} \n\n ${this.resource.question.customization1}`
    );

    this.popupJP = new PopUpInfoVM(
      this.resource.infobulle.legalProtection1,
      '',
      this.resource.infobulle.legalProtection2C,
      '',
      ''
    );

    this.popupBP = new PopUpInfoVM(
      this.resource.infobulle.bikeProtection1,
      '',
      this.resource.infobulle.bikeProtection2,
      this.resource.infobulle.bikeProtection3,
      ''
    );

    this.popupMP = new PopUpInfoVM(
      this.resource.infobulle.nomadeProtection1,
      '',
      this.resource.infobulle.nomadeProtection2,
      this.resource.infobulle.nomadeProtection3,
      ''
    );

    this.popupSI = new PopUpInfoVM(this.resource.infobulle.schoolInsurance, '', '', '', '');
    //#endregion
  }

  override async ngOnInit() {
    super.ngOnInit();

    this.path = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));

    this.productClass = getMetropoleClassOne(this.path) ?? '';

    let contextId = await lastValueFrom(
      this.store.select(fromContext.selectContextId).pipe(take(1))
    );
    this.context = contextId!;

    this.address = await lastValueFrom(this.store.select(fromAddress.selectAddress).pipe(take(1)));

    this.saisieHabitation = await (
      await lastValueFrom(this.store.select(fromHousing.selectHousing).pipe(take(1)))
    ).SaisieHabitation;

    this.tarification = await lastValueFrom(
      this.store.select(fromTarification.selectTarificationState).pipe(take(1))
    );

    const offerCustomization = await lastValueFrom(
      this.store.select(fromOfferCustomization.selectOfferCustomization).pipe(take(1))
    );
    this.offer = offerCustomization.SaisieFormule;
    this.formuleRecommandee = offerCustomization.FormuleRecommandee!;

    const subscriber = await lastValueFrom(
      this.store.select(fromSubscriber.selectSubscriber).pipe(take(1))
    );
    this.saisieInfosSouscripteur = subscriber.subscriber!;

    this.customization = await lastValueFrom(
      this.store.select(fromOfferCustomization.selectSaisiePersonnalisation).pipe(take(1))
    );

    this.currentFormule = this.tarification.Formules?.find(
      x => x.NomFormule === this.offer.FormuleSelectionnee
    )!;

    this.clientAdvisorFullName = await this.clientAdvisorService.getClientAdvisorFullName();

    await this.initRateBase();

    this.customizeOfferVm = new CustomizeOfferVM(
      this.currencyPipe.transform(roundToMonth(this.rate, 2).toString()),
      String.format(
        this.resource.offercustomization.rate10k,
        roundToMonth(this.currentFormule?.PrimesOptions?.ObjetsValeur?.Valeur10000!, 2)
          .toString()
          .replace('.', ',')
      ),
      String.format(
        this.resource.offercustomization.rate20k,
        roundToMonth(this.currentFormule?.PrimesOptions?.ObjetsValeur?.Valeur15000!, 2)
          .toString()
          .replace('.', ',')
      ),
      String.format(
        this.resource.offercustomization.subtitleJP,
        roundToMonth(this.currentFormule?.PrimesOptions?.ProtectionJuridique, 2)
          .toString()
          .replace('.', ',')
      ),
      String.format(
        this.resource.offercustomization.subtitleBP,
        roundToMonth(this.currentFormule?.PrimesOptions?.ProtectionVelos, 2)
          .toString()
          .replace('.', ',')
      ),
      String.format(
        this.resource.offercustomization.subtitleNP,
        roundToMonth(this.currentFormule?.PrimesOptions?.ProtectionNomades, 2)
          .toString()
          .replace('.', ',')
      ),
      String.format(
        this.resource.offercustomization.subtitleSI,
        roundToMonth(this.currentFormule?.PrimesOptions?.AssuranceScolaire?.Enfant1!, 2)
          .toString()
          .replace('.', ',')
      ),
      this.options
    );

    this.setOptionBase();
  }

  setOptionBase() {
    if (this.iB_CP) {
      if (this.promo)
        this.baseOption = String.format(
          this.resource.offercustomization.baseConfortPlusPromo,
          this.codePromo
        );
      else this.baseOption = this.resource.offercustomization.baseConfortPlus;
    } else if (this.iB_C) {
      if (this.promo)
        this.baseOption = String.format(
          this.resource.offercustomization.baseConfortPromo,
          this.codePromo
        );
      else this.baseOption = this.resource.offercustomization.baseConfort;
    } else if (this.iB_P) {
      const baseName = getMetropoleBaseName(this.path);
      if (baseName) {
        if (this.promo)
          this.baseOption = String.format(
            this.resource.offercustomization.baseMetropolePromo,
            baseName,
            this.codePromo
          );
        else
          this.baseOption = String.format(this.resource.offercustomization.baseMetropole, baseName);
      }
    } else if (this.iB_S) {
      if (this.promo)
        this.baseOption = String.format(
          this.resource.offercustomization.baseSolidairePromo,
          this.codePromo
        );
      else this.baseOption = this.resource.offercustomization.baseSolidaire;
    }

    this.options.push({
      id: OfferCustomizationEnum.Base,
      name: this.baseOption,
      valueShow: this.currencyPipe.transform(this.offer.ValeurMensuelle),
      monthlyValue: this.offer.ValeurMensuelle,
      annuelValue: this.offer.ValeurAnnuelle,
      optional: false
    });

    this.initDataState();
    this.updateRate();
  }

  selectValueInsured(value: string) {
    switch (value) {
      case OfferCustomizationEnum.FiveK:
        this.equal5k = true;
        this.equal10k = false;
        this.equal20k = false;
        this.updateOption(OfferCustomizationEnum.Object, OfferCustomizationEnum.FiveK);
        break;
      case OfferCustomizationEnum.TenK:
        this.equal5k = false;
        this.equal10k = true;
        this.equal20k = false;
        this.updateOption(OfferCustomizationEnum.Object, OfferCustomizationEnum.TenK);
        break;
      case OfferCustomizationEnum.TwentyK:
        this.equal5k = false;
        this.equal10k = false;
        this.equal20k = true;
        this.updateOption(OfferCustomizationEnum.Object, OfferCustomizationEnum.TwentyK);
        break;
    }
  }

  async initRateBase() {
    if (this.path != PathType.HOUSING)
      this.popupJP.textThree = this.resource.infobulle.legalProtection2PS;

    if (this.path == PathType.HOUSING) {
      if (this.offer.FormuleSelectionnee == Formule.F1) {
        this.iB_C = true;
        this.customizeOffer = true;
      } else if (this.offer.FormuleSelectionnee == Formule.F2) {
        this.iB_CP = true;
        if (!this.saisieHabitation.ObjetsValeurSuperieur2000) this.customizeOffer = true;
      }
    } else if (this.path == PathType.HOUSING_SOLIDARITY) {
      this.iB_S = true;
      this.customizeOffer = true;
    } else if (
      this.path == PathType.HOUSING_PARIS ||
      this.path == PathType.HOUSING_TOURCOING ||
      this.path == PathType.HOUSING_LILLE
    ) {
      this.iB_P = true;
      this.customizeOffer = true;
    } else {
      if (this.offer.FormuleSelectionnee == Formule.F1) {
        this.iB_C = true;
        this.customizeOffer = true;
      } else if (this.offer.FormuleSelectionnee == Formule.F2) {
        this.iB_CP = true;
        if (!this.saisieHabitation.ObjetsValeurSuperieur2000) this.customizeOffer = true;
      }
    }

    if (this.customizeOffer)
      this.question = {
        libelle: this.resource.question.customization2
      };

    this.rate = this.currentFormule?.PrimeMensuelle!;

    if (this.offer.CodePromo != null && this.offer.CodePromo != '') {
      this.promo = true;
      this.codePromo = this.offer.CodePromo;
    }
  }

  initDataState() {
    if (this.customization.MontantValeurObjets2000 != null) {
      this.equal5k = false;
      switch (this.customization.MontantValeurObjets2000) {
        case MontantValeurObjets2000.Montant5000:
          this.equal5k = true;
          if (this.iB_CP) {
            this.options.push({
              id: OfferCustomizationEnum.Object,
              name: this.resource.offercustomization.option5k,
              valueShow: this.resource.offercustomization.rateInclude,
              monthlyValue: 0,
              annuelValue: 0,
              optional: false
            });
          }
          break;
        case MontantValeurObjets2000.Montant10000:
          this.equal10k = true;
          this.options.push({
            id: OfferCustomizationEnum.Object,
            name: this.resource.offercustomization.option10k,
            valueShow: this.currencyPipe.transform(
              roundToMonth(this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur10000, 2)
            ),
            monthlyValue: roundToMonth(
              this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur10000,
              2
            ),
            annuelValue: this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur10000!,
            optional: false
          });
          break;
        case MontantValeurObjets2000.Montant20000:
          this.equal20k = true;
          this.options.push({
            id: OfferCustomizationEnum.Object,
            name: this.resource.offercustomization.option20k,
            valueShow: this.currencyPipe.transform(
              roundToMonth(this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur15000, 2)
            ),
            monthlyValue: roundToMonth(
              this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur15000,
              2
            ),
            annuelValue: this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur15000!,
            optional: false
          });
          break;
      }
    } else {
      if (this.iB_CP) {
        this.options.push({
          id: OfferCustomizationEnum.Object,
          name: this.resource.offercustomization.option5k,
          valueShow: this.resource.offercustomization.rateInclude,
          monthlyValue: 0,
          annuelValue: 0,
          optional: false
        });
      }
    }

    if (this.customization.ProtectionVelos) {
      this.bikeProtect = this.customization.ProtectionVelos;
      this.options.push({
        id: OfferCustomizationEnum.BP,
        name: this.resource.offercustomization.optionBP,
        valueShow: this.currencyPipe.transform(
          roundToMonth(this.currentFormule.PrimesOptions?.ProtectionVelos!, 2)
        ),
        monthlyValue: roundToMonth(this.currentFormule.PrimesOptions?.ProtectionVelos!, 2),
        annuelValue: this.currentFormule.PrimesOptions?.ProtectionVelos!,
        optional: true
      });
    }

    if (this.customization.ProtectionJuridique) {
      this.juridProtect = this.customization.ProtectionJuridique;
      this.options.push({
        id: OfferCustomizationEnum.JP,
        name: this.resource.offercustomization.optionJP,
        valueShow: this.currencyPipe.transform(
          roundToMonth(this.currentFormule.PrimesOptions?.ProtectionJuridique!, 2)
        ),
        monthlyValue: roundToMonth(this.currentFormule.PrimesOptions?.ProtectionJuridique!, 2),
        annuelValue: this.currentFormule.PrimesOptions?.ProtectionJuridique!,
        optional: true
      });
    }

    if (this.customization.ProtectionNomades) {
      this.nomadeProtect = this.customization.ProtectionNomades;
      this.options.push({
        id: OfferCustomizationEnum.NP,
        name: this.resource.offercustomization.optionNP,
        valueShow: this.currencyPipe.transform(
          roundToMonth(this.currentFormule.PrimesOptions?.ProtectionNomades!, 2)
        ),
        monthlyValue: roundToMonth(this.currentFormule.PrimesOptions?.ProtectionNomades!, 2),
        annuelValue: this.currentFormule.PrimesOptions?.ProtectionNomades!,
        optional: true
      });
    }

    if (this.customization.AssuranceScolaire != undefined) {
      this.schoolInsurance = true;
      this.options.push({
        id: OfferCustomizationEnum.SI,
        name: this.resource.offercustomization.optionSI,
        valueShow: '',
        monthlyValue: 0,
        annuelValue: 0,
        optional: true
      });
    }
  }

  updateOption(id: string, optionSelected: string) {
    let option = this.options.find(x => x.id == id);
    if (option != null) {
      switch (optionSelected) {
        case OfferCustomizationEnum.FiveK:
          option.name = this.resource.offercustomization.option5k;
          option.valueShow = this.resource.offercustomization.rateInclude;
          option.monthlyValue = 0;
          option.annuelValue = 0;
          this.store.dispatch(
            fromOfferCustomization.patchOfferCustomization({
              payload: {
                MontantValeurObjets2000: MontantValeurObjets2000.Montant5000
              }
            })
          );
          break;
        case OfferCustomizationEnum.TenK:
          option.name = this.resource.offercustomization.option10k;
          option.valueShow = this.currencyPipe.transform(
            roundToMonth(this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur10000!, 2)
          );
          option.monthlyValue = roundToMonth(
            this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur10000!,
            2
          );
          option.annuelValue = this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur10000!;
          this.store.dispatch(
            fromOfferCustomization.patchOfferCustomization({
              payload: {
                MontantValeurObjets2000: MontantValeurObjets2000.Montant10000
              }
            })
          );
          break;
        case OfferCustomizationEnum.TwentyK:
          option.name = this.resource.offercustomization.option20k;
          option.valueShow = this.currencyPipe.transform(
            roundToMonth(this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur15000, 2)
          );
          option.monthlyValue = roundToMonth(
            this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur15000,
            2
          );
          option.annuelValue = this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur15000!;
          this.store.dispatch(
            fromOfferCustomization.patchOfferCustomization({
              payload: {
                MontantValeurObjets2000: MontantValeurObjets2000.Montant20000
              }
            })
          );
          break;
      }
      this.updateRate();
    }
  }

  updateRate() {
    let total = 0;
    if (
      this.options.some(option => option.name === this.resource.offercustomization.option5k) &&
      this.options.length == 2
    ) {
      total = this.options[0].monthlyValue;
    } else {
      this.options.forEach(element => {
        total += element.annuelValue;
      });
      total = roundToMonth(total, 2);
    }
    this.customizeOfferVm.rate = this.currencyPipe.transform(total);
  }

  updateOptionSwitch(value: string) {
    switch (value) {
      case OfferCustomizationEnum.JP:
        if (this.juridProtect)
          this.options.push({
            id: OfferCustomizationEnum.JP,
            name: this.resource.offercustomization.optionJP,
            valueShow: this.currencyPipe.transform(
              roundToMonth(this.currentFormule.PrimesOptions?.ProtectionJuridique, 2)
            ),
            monthlyValue: roundToMonth(this.currentFormule.PrimesOptions?.ProtectionJuridique, 2),
            annuelValue: this.currentFormule.PrimesOptions?.ProtectionJuridique!,
            optional: true
          });
        else {
          let index = this.options.findIndex(x => x.id == OfferCustomizationEnum.JP);
          if (index != -1) this.options.splice(index, 1);
        }
        break;
      case OfferCustomizationEnum.BP:
        if (this.bikeProtect)
          this.options.push({
            id: OfferCustomizationEnum.BP,
            name: this.resource.offercustomization.optionBP,
            valueShow: this.currencyPipe.transform(
              roundToMonth(this.currentFormule.PrimesOptions?.ProtectionVelos!, 2)
            ),
            monthlyValue: roundToMonth(this.currentFormule.PrimesOptions?.ProtectionVelos!, 2),
            annuelValue: this.currentFormule.PrimesOptions?.ProtectionVelos!,
            optional: true
          });
        else {
          let index = this.options.findIndex(x => x.id == OfferCustomizationEnum.BP);
          if (index != -1) this.options.splice(index, 1);
        }
        break;
      case OfferCustomizationEnum.NP:
        if (this.nomadeProtect)
          this.options.push({
            id: OfferCustomizationEnum.NP,
            name: this.resource.offercustomization.optionNP,
            valueShow: this.currencyPipe.transform(
              roundToMonth(this.currentFormule.PrimesOptions?.ProtectionNomades!, 2)
            ),
            monthlyValue: roundToMonth(this.currentFormule.PrimesOptions?.ProtectionNomades!, 2),
            annuelValue: this.currentFormule.PrimesOptions?.ProtectionNomades!,
            optional: true
          });
        else {
          let index = this.options.findIndex(x => x.id == OfferCustomizationEnum.NP);
          if (index != -1) this.options.splice(index, 1);
        }
        break;
      case OfferCustomizationEnum.SI:
        if (this.schoolInsurance)
          this.options.push({
            id: OfferCustomizationEnum.SI,
            name: this.resource.offercustomization.optionSI,
            valueShow: '',
            monthlyValue: 0,
            annuelValue: 0,
            optional: true
          });
        else {
          let index = this.options.findIndex(x => x.id == OfferCustomizationEnum.SI);
          if (index != -1) this.options.splice(index, 1);
        }
        break;
    }

    this.updateRate();
  }

  deleteOption(id: string) {
    let index = this.options.findIndex(x => x.id == id);
    if (index != -1) this.options.splice(index, 1);

    switch (id) {
      case OfferCustomizationEnum.JP:
        this.juridProtect = false;
        break;
      case OfferCustomizationEnum.BP:
        this.bikeProtect = false;
        break;
      case OfferCustomizationEnum.NP:
        this.nomadeProtect = false;
        break;
      case OfferCustomizationEnum.SI:
        this.schoolInsurance = false;
        break;
    }

    this.updateRate();
  }

  nextStepCO() {
    this.question = {
      libelle: this.resource.question.customization2
    };
    this.customizeOffer = true;
  }

  async nextStep() {
    this.isLoading = true;

    let schoolAssurance = undefined;
    let isValid = true;
    if (this.schoolInsurance) {
      schoolAssurance = [];
      isValid = false;
    }

    await this.updateStepper(this.schoolInsurance);

    if (this.equal10k) this.montantValeurObjets = MontantValeurObjets2000.Montant10000;
    else if (this.equal20k) this.montantValeurObjets = MontantValeurObjets2000.Montant20000;
    else this.montantValeurObjets = MontantValeurObjets2000.Montant5000;

    this.store.dispatch(
      fromOfferCustomization.patchOfferCustomization({
        payload: {
          MontantValeurObjets2000: this.montantValeurObjets,
          ProtectionJuridique: this.juridProtect,
          ProtectionNomades: this.nomadeProtect,
          ProtectionVelos: this.bikeProtect,
          AssuranceScolaire: schoolAssurance
        }
      })
    );

    this.store.dispatch(
      fromOfferCustomization.changeValidationStatus({
        isValid: isValid
      })
    );

    if (this.schoolInsurance) this.router.navigate([SCHOOL_INSURANCE_PATH]);
    else {
      // Create new tarif with personalization and create devis
      const bodyTarif = ApiRequestBodyUtils.GenerateTarifCreateRequestBodyWithPersonnalisation(
        this.context,
        this.path,
        this.brand,
        this.address.adresseType,
        this.address.addresseSelection,
        this.address.addresseManuelle,
        this.address.adresseEsh,
        this.saisieHabitation,
        this.offer,
        this.formuleRecommandee,
        {
          MontantValeurObjets2000: this.montantValeurObjets,
          ProtectionJuridique: this.juridProtect,
          ProtectionNomades: this.nomadeProtect,
          ProtectionVelos: this.bikeProtect,
          AssuranceScolaire: schoolAssurance
        }
      );

      const bodyDevis = ApiRequestBodyUtils.GenerateDevisCreateRequestBody(
        this.context,
        this.clientAdvisorFullName,
        this.path,
        this.brand,
        this.address.adresseType,
        this.address.addresseSelection,
        this.address.addresseManuelle,
        this.address.adresseEsh,
        this.saisieHabitation,
        this.saisieInfosSouscripteur,
        this.offer,
        this.formuleRecommandee
      );

      this.quotationService.CreateQuotation(bodyTarif, bodyDevis).subscribe({
        next: resQuotation => {
          this.quotationSummaryService.processQuotationSummary(
            resQuotation.quotationReference,
            this.context,
            this.saisieInfosSouscripteur.Email
          );
        },
        error: err => {
          this.displayError = true;
          this.isLoading = false;
        },
        complete: () => (this.isLoading = false)
      });
    }
  }

  async updateStepper(schoolinsurance: boolean) {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.offer
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.offercustomization
    );

    if (subtitleS) subtitleS.isValid = true;

    let subtitleN;
    if (schoolinsurance)
      subtitleN = title?.subtitles.find(
        (x: { url: string }) => x.url == this.resource.stepper.schoolinsurance
      );
    else {
      subtitleN = title?.subtitles.find(
        (x: { url: string }) => x.url == this.resource.stepper.confirmquote
      );

      let titleN = tmpStepper.devis.titles.find(
        (x: { libelle: string }) => x.libelle == this.resource.stepper.quote
      );

      if (titleN) titleN.isActive = true;

      let subtitleNS = titleN?.subtitles.find(
        (x: { url: string }) => x.url == this.resource.stepper.confirmquote
      );

      if (subtitleNS) subtitleNS.isActive = true;
    }

    if (subtitleN) {
      subtitleN.isActive = true;
      subtitleN.isVisible = true;
    }

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  setClassActive(value: boolean): string {
    return value ? 'active' : 'inactive';
  }

  setInputSwitch(choice: string) {
    switch (choice) {
      case OfferCustomizationEnum.JP:
        this.juridProtect = !this.juridProtect;
        break;
      case OfferCustomizationEnum.BP:
        this.bikeProtect = !this.bikeProtect;
        break;
      case OfferCustomizationEnum.NP:
        this.nomadeProtect = !this.nomadeProtect;
        break;
      case OfferCustomizationEnum.SI:
        this.schoolInsurance = !this.schoolInsurance;
        break;
    }

    this.updateOptionSwitch(choice);
  }
}
