import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { DevisDocumentType } from 'src/app/api/models/quotationDocumentType';
import { QuotationSummaryResponse } from 'src/app/api/models/quotationSummaryResponse.model';
import { QuotationService } from 'src/app/api/services/quotation.service';
import {
  AssuranceScolaire,
  Civilite,
  Formule,
  MontantValeurObjets2000,
  PrimesAssuranceScolaire,
  PrimesOptions,
  QualiteOccupant,
  TypePromo
} from 'src/app/core/models/form-state.model';
import * as fromAddress from 'src/app/core/state/address';
import * as fromDevis from 'src/app/core/state/devis';
import * as fromHousing from 'src/app/core/state/housing';
import * as fromOfferCustomization from 'src/app/core/state/offer-customization';
import * as fromSubscriber from 'src/app/core/state/subscriber';
import * as fromTarification from 'src/app/core/state/tarification';
import * as fromRoot from '../../core/state/core.state';
import { MOIS } from '../constants/promotion-type.constants';
import { CONFIRM_QUOTE_PATH, ROOT_PATH } from '../constants/route.constants';
import { AssurancesScolaire } from '../models/enum/assurancescolaire.enum';
import { PackageCode } from '../models/enum/packageCode.enum';
import { PrimeOptions } from '../models/enum/primeoptions.enum';
import { RiskItemInformations } from '../models/enum/riskiteminformations.enum';

@Injectable({
  providedIn: 'root'
})
export class QuotationSummaryService {
  constructor(
    private quotationService: QuotationService,
    private store: Store<fromRoot.State>,
    private router: Router
  ) {}

  public processQuotationSummary(referenceDevis: string, contextId: string, email: string) {
    this.quotationService
      .GetQuotationSummary(referenceDevis, Guid.parse(contextId).toJSON().value, email)
      .subscribe(data => {
        this.dispatchDevis(data);
        this.dispatchSaisieFormule(data);
        this.dispatchSubscriber(data);
        this.dispatchEsh(data);
        this.dispatchOccupantType(data);
        this.dispatchOfferCustomization(data);
        this.dispatchCodePromo(data);

        this.router.navigate([`${ROOT_PATH}${CONFIRM_QUOTE_PATH}`]);
      });
  }

  private dispatchDevis(dataDevis: QuotationSummaryResponse) {
    let assScolaire = dataDevis.formulasAndOptionsInformations.options.find(
      x => x.type === PrimeOptions.AssuranceScolaire
    )?.pricedMonthlyAmount;

    let primeAssuranceScolaire: PrimesAssuranceScolaire = {
      Enfant1: assScolaire,
      Enfant2: assScolaire,
      Enfant3: assScolaire,
      Enfant4Plus: assScolaire
    };

    let primesOptions: PrimesOptions = {
      ObjetsValeur: {
        Valeur10000: dataDevis?.formulasAndOptionsInformations?.valuableItem?.pricedAmountTTC,
        Valeur15000: dataDevis?.formulasAndOptionsInformations?.valuableItem?.pricedAmountTTC
      },
      ProtectionJuridique: dataDevis?.formulasAndOptionsInformations?.options.find(
        x =>
          x.type === PrimeOptions.ProtectionJuridique ||
          x.type === PrimeOptions.ProtectionJuridiqueSOL
      )?.pricedMonthlyAmount,
      ProtectionVelos: dataDevis?.formulasAndOptionsInformations?.options.find(
        x => x.type === PrimeOptions.ProtectionVelos
      )?.pricedMonthlyAmount,
      ProtectionNomades: dataDevis?.formulasAndOptionsInformations?.options.find(
        x => x.type === PrimeOptions.ProtectionNomades
      )?.pricedMonthlyAmount,
      AssuranceScolaire: primeAssuranceScolaire
    };
    this.store.dispatch(
      fromDevis.patchDevis({
        payload: {
          NumeroDevis: dataDevis.reference,
          DevisId: dataDevis.quotationId,
          DevisStatus: dataDevis.quotationStateId,
          GDAPositif: !!dataDevis.clientInformations.gdaTypeId,
          PPEPositif: dataDevis.clientInformations.isPpe,
          PrimeMensuelle: dataDevis.summaryInformation.monthlyPremiumTTC,
          PrimeAnnuelle: dataDevis.summaryInformation.annualPremiumTTC,
          PrimesOptions: primesOptions,
          AgreementId: dataDevis.agreementId,
          DocumentDevis: {
            documentId: dataDevis.summaryInformation.quotationFileId.toString(),
            documentTypeCode: DevisDocumentType.DEV
          },
          DocumentFIC: {
            documentId: dataDevis.summaryInformation.ficFileId.toString(),
            documentTypeCode: DevisDocumentType.FIC
          },
          DocumentInfo: {
            documentId: dataDevis.summaryInformation.productInformationFileId.toString(),
            documentTypeCode: DevisDocumentType.DIPA
          },
          DocumentCG: {
            documentId: dataDevis.summaryInformation.generalConditionFileId.toString(),
            documentTypeCode: DevisDocumentType.CG
          }
        }
      })
    );
  }

  private dispatchSaisieFormule(dataDevis: QuotationSummaryResponse) {
    let formuleSelectionnee: Formule;
    if (dataDevis.formulasAndOptionsInformations.formulaCode === PackageCode.CONF) {
      formuleSelectionnee = Formule.F1;
    } else if (dataDevis.formulasAndOptionsInformations.formulaCode === PackageCode.CONF_PLUS) {
      formuleSelectionnee = Formule.F2;
    } else {
      formuleSelectionnee = Formule.LM;
    }

    this.store.dispatch(
      fromOfferCustomization.patchSaisieFormule({
        payload: {
          FormuleSelectionnee: formuleSelectionnee,
          ValeurMensuelle: dataDevis.summaryInformation.monthlyPriceSocleTTC
        }
      })
    );
  }

  private dispatchSubscriber(dataDevis: QuotationSummaryResponse) {
    let civilite: Civilite;
    if (Number(dataDevis.clientInformations.civility) === 1) {
      civilite = Civilite.Monsieur;
    } else if (Number(dataDevis.clientInformations.civility) === 2) {
      civilite = Civilite.Madame;
    } else {
      civilite = Civilite.Autre;
    }

    this.store.dispatch(
      fromSubscriber.patchSubscriber({
        payload: {
          Civilite: civilite,
          Nom: dataDevis.clientInformations.lastName,
          Prenom: dataDevis.clientInformations.firstName,
          Email: dataDevis.clientInformations.email,
          DateNaissance: dataDevis.clientInformations.birthDate,
          LieuNaissance: dataDevis.clientInformations.birthCity,
          PaysNaissance: dataDevis.clientInformations.birthCountry,
          Indicatif: dataDevis.clientInformations.cellPhone
            ? dataDevis.clientInformations.phonePrefix
            : '',
          EstClient: dataDevis.clientInformations.isEnrolled,
          Telephone: dataDevis.clientInformations.cellPhone
        }
      })
    );
  }

  private dispatchEsh(dataDevis: QuotationSummaryResponse) {
    this.store.dispatch(
      fromAddress.patchEsh({
        payload: { NomBailleur: dataDevis.summaryInformation.distributorName }
      })
    );
  }

  private dispatchOccupantType(dataDevis: QuotationSummaryResponse) {
    let qualiteOccupant;
    switch (
      dataDevis.riskItemInformations.values.find(
        x => x.code === RiskItemInformations.Nature_Occupation
      )?.value
    ) {
      case QualiteOccupant.Proprietaire:
        qualiteOccupant = QualiteOccupant.Proprietaire;
        break;
      case QualiteOccupant.Locataire:
        qualiteOccupant = QualiteOccupant.Locataire;
        break;
    }
    this.store.dispatch(
      fromHousing.patchOccupantType({
        payload: {
          QualiteOccupant: qualiteOccupant
        }
      })
    );
  }

  private dispatchOfferCustomization(dataDevis: QuotationSummaryResponse) {
    let montantValeurObjets2000;
    switch (dataDevis.formulasAndOptionsInformations.valuableItem.value.trim()) {
      case MontantValeurObjets2000.Montant5000:
        montantValeurObjets2000 = MontantValeurObjets2000.Montant5000;
        break;
      case MontantValeurObjets2000.Montant10000:
        montantValeurObjets2000 = MontantValeurObjets2000.Montant10000;
        break;
      case MontantValeurObjets2000.Montant20000:
        montantValeurObjets2000 = MontantValeurObjets2000.Montant20000;
        break;
      default:
        montantValeurObjets2000 = MontantValeurObjets2000.Montant5000;
        break;
    }

    let assuranceScolaire = dataDevis.formulasAndOptionsInformations.options.find(
      x => x.type === PrimeOptions.AssuranceScolaire
    )?.isSelected
      ? dataDevis.formulasAndOptionsInformations.options
          .find(x => x.type === PrimeOptions.AssuranceScolaire)
          ?.riskItemValues.map(x => x.values)
      : null;

    let enfantsAssuranceScolaire: AssuranceScolaire[] = [];
    assuranceScolaire?.forEach((enfant: any[], index: number) => {
      let childRiskItem: AssuranceScolaire = {
        Numero: index + 1,
        Nom: enfant.find(x => x.code === AssurancesScolaire.NomEnfant)?.value.toString() ?? '',
        Prenom:
          enfant.find(x => x.code === AssurancesScolaire.PrenomEnfant)?.value.toString() ?? '',
        DateNaissance:
          enfant.find(x => x.code === AssurancesScolaire.DateNaissanceEnfant)?.value.toString() ??
          ''
      };
      enfantsAssuranceScolaire.push(childRiskItem);
    });

    this.store.dispatch(
      fromOfferCustomization.patchOfferCustomization({
        payload: {
          ProtectionVelos: dataDevis.formulasAndOptionsInformations.options.find(
            x => x.type === PrimeOptions.ProtectionVelos
          )?.isSelected,
          ProtectionJuridique: dataDevis.formulasAndOptionsInformations.options.find(
            x =>
              x.type === PrimeOptions.ProtectionJuridique ||
              x.type === PrimeOptions.ProtectionJuridiqueSOL
          )?.isSelected,
          ProtectionNomades: dataDevis.formulasAndOptionsInformations.options.find(
            x => x.type === PrimeOptions.ProtectionNomades
          )?.isSelected,
          AssuranceScolaire:
            enfantsAssuranceScolaire.length > 0 ? enfantsAssuranceScolaire : undefined,
          MontantValeurObjets2000: montantValeurObjets2000
        }
      })
    );
  }

  private dispatchCodePromo(dataDevis: QuotationSummaryResponse) {
    const promo = dataDevis.summaryInformation.promotionalCode;
    if (promo != null) {
      this.store.dispatch(
        fromTarification.patchTarificationPromo({
          payload: {
            CodePromoApplique: !!dataDevis.summaryInformation.promotionalCode,
            DetailsPromo: dataDevis.summaryInformation.promotionalCodeLabel,
            TypePromo:
              dataDevis.summaryInformation.promotionalCodeType === MOIS
                ? TypePromo.MoisOfferts
                : (dataDevis.summaryInformation.promotionalCodeType as TypePromo),
            CodePromo: dataDevis.summaryInformation.promotionalCode
          }
        })
      );
    }
  }
}
