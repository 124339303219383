import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { distinctUntilChanged, lastValueFrom, map, merge, take } from 'rxjs';
import { CountryModel } from 'src/app/api/models/country.model';
import { VyVTarifCheckAddressRequest } from 'src/app/api/models/vyVTarifCheckAddressRequest';
import { CountryService } from 'src/app/api/services/country.service';
import { DistributorService } from 'src/app/api/services/distributor.service';
import { EligibilityService } from 'src/app/api/services/eligibility.service';
import { AppResource } from 'src/app/app.resource';
import { Esh, Selection, Selectionne } from 'src/app/core/models/form-state.model';
import { Info } from 'src/app/core/models/info.model';
import { Question } from 'src/app/core/models/question.model';
import { cloneResumeStep } from 'src/app/core/models/resume-step.model';
import { Step } from 'src/app/core/models/step.model';
import * as fromContext from 'src/app/core/state/context';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { MatomoConstants } from 'src/app/shared/constants/matomo.constants';
import { Place } from 'src/app/shared/models/components/place.model';
import { AdresseDataGouvService } from 'src/app/shared/services/adresse-data-gouv.service';
import { Stepper } from '../../../core/models/stepper.model';
import * as fromAddress from '../../../core/state/address';
import * as fromRoot from '../../../core/state/core.state';
import * as fromInfo from '../../../core/state/info';

import { HOUSE_APARTMENT_PATH, ROOT_PATH } from 'src/app/shared/constants/route.constants';
import { metropolePathSwitch } from 'src/app/shared/helpers/metropole-helper.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent extends BaseComponent implements OnInit {
  addressForm!: FormGroup;
  autocompleteAddressForm!: FormGroup;

  isAutocomplete: boolean = true;
  placeSubscription: any;

  isLoading: boolean = false;
  displayError: boolean = false;
  displayModal: boolean = false;

  errorMessageApi!: string;

  filteredAdresses = new Array<Place>();

  countries = new Array<CountryModel>();
  filteredCountries = new Array<CountryModel>();

  override step: Step = {
    step: this.resource.header.stepHousing,
    stepNumber: 1,
    subStep: this.resource.header.subStepAddress,
    subStepNumber: 1
  };

  override question: Question = {
    libelle: this.resource.question.address,
    popupInfo: undefined
  };

  override info: Info = {
    title: this.resource.infotop.firstStep,
    subTitle: this.resource.infotop.firstStepB,
    logo: this.resource.infotop.logoTime,
    secondTitle: '',
    isVisible: true
  };
  addressType!: Selectionne;

  public override currentPage = MatomoConstants.ADDRESS_ESH;

  get address() {
    return this.addressForm.get('address');
  }

  get city() {
    return this.addressForm.get('city');
  }

  get postalCode() {
    return this.addressForm.get('postalCode');
  }

  get country() {
    return this.addressForm.get('country');
  }

  get adresseAutocomplete() {
    return this.autocompleteAddressForm?.get('address')!;
  }

  constructor(
    store: Store<fromRoot.State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private router: Router,
    private adresseDataGouvService: AdresseDataGouvService,
    private eligibilityService: EligibilityService,
    private countryService: CountryService
  ) {
    super(store, resources, tracker);

    this.errorMessageApi = this.resource.error.gouvAddressText;
  }

  override async ngOnInit() {
    super.ngOnInit();

    await this.updateStepper(true);
    this.updateResumeStepVisibility(false);
    await this.loadCountries();
    this.initForm();
    this.initData();
  }

  async loadCountries() {
    this.countries = await lastValueFrom(this.countryService.GetCountries().pipe(take(1)));
    this.filteredCountries = this.countries;
  }

  private async initData() {
    this.addressType = await lastValueFrom(
      this.store.select(fromAddress.selectAddressTypeData).pipe(take(1))
    );
    if (this.addressType == Selectionne.Undefined) {
      this.store.dispatch(fromAddress.patchSelectedAddress({ adresseType: Selectionne.Selection }));
    } else {
      await this.initAddress();
    }

    this.store.dispatch(fromAddress.patchSelectedAddress({ adresseType: Selectionne.Selection }));
  }

  private initForm() {
    this.addressForm = new FormGroup({
      country: new FormControl<CountryModel | null>(
        this.countries.find(x => x.countryNameFr == 'France')!,
        {
          validators: [Validators.required],
          nonNullable: true,
          updateOn: 'change'
        }
      ),
      postalCode: new FormControl<string | null>(null, {
        validators: [Validators.required, Validators.pattern('^[0-9]{5}$')],
        nonNullable: true,
        updateOn: 'blur'
      }),
      address: new FormControl<string | null>('', {
        validators: [Validators.required],
        nonNullable: true,
        updateOn: 'blur'
      }),
      city: new FormControl<string | null>('', {
        validators: [Validators.required],
        nonNullable: true,
        updateOn: 'blur'
      })
    });

    this.autocompleteAddressForm = new FormGroup({
      address: new FormControl<Place | null>(null, {
        validators: [Validators.required],
        nonNullable: true,
        updateOn: 'change'
      })
    });

    const addressSelection$ = this.adresseAutocomplete?.valueChanges.pipe(
      map((val: any) => {
        if (val) {
          var Adresse = val.place;
          var AdresseEsh = {
            CodePostal: val.postalCode,
            Ville: val.city,
            Numero: val.streetNumber,
            Rue: val.street,
            NumeroEtRue: `${val.streetNumber}, ${val.street}`,
            Pays: val.country
          };
          return { Adresse, AdresseEsh } as Partial<Selection>;
        }
        var Adresse = null;
        return { Adresse } as Partial<Selection>;
      })
    );

    const address$ = this.address?.valueChanges.pipe(
      map((NumeroEtRue: string) => ({ NumeroEtRue } as Partial<Esh>))
    );
    const postalCode$ = this.postalCode?.valueChanges.pipe(
      map((CodePostal: string) => ({ CodePostal } as Partial<Esh>))
    );
    const city$ = this.city?.valueChanges.pipe(map((Ville: string) => ({ Ville } as Partial<Esh>)));

    const country$ = this.country?.valueChanges.pipe(
      map((val: CountryModel) => {
        let Pays = val?.countryNameFr;
        return { Pays } as Partial<Esh>;
      })
    );

    merge(address$!, postalCode$!, city$!, country$!).subscribe((payload: Partial<Esh>) => {
      this.store.dispatch(fromAddress.patchManuelle({ payload }));
    });

    addressSelection$?.subscribe((payload: Partial<Selection>) => {
      this.store.dispatch(fromAddress.patchSelection({ payload }));
    });

    this.addressForm.valueChanges
      .pipe(
        map(() => this.addressForm.valid),
        distinctUntilChanged()
      )
      .subscribe((isValid: boolean) => {
        this.store.dispatch(fromAddress.changeValidationStatus({ isValid }));
      });

    this.autocompleteAddressForm.valueChanges
      .pipe(
        map(() => this.autocompleteAddressForm.valid),
        distinctUntilChanged()
      )
      .subscribe((isValid: boolean) => {
        if (isValid) {
        }
        this.store.dispatch(fromAddress.changeValidationStatus({ isValid }));
      });
  }

  private async initAddress() {
    if (this.addressType == Selectionne.Manuel) {
      this.isAutocomplete = false;
      var address = await lastValueFrom(
        this.store.select(fromAddress.selectAddressManuelleGroupData).pipe(take(1))
      );
      this.address?.patchValue(address.NumeroEtRue, { emitEvent: false });
      this.postalCode?.patchValue(address.CodePostal, { emitEvent: false });
      this.city?.patchValue(address.Ville, { emitEvent: false });
      this.country?.patchValue(this.countries.find(x => x.countryNameFr == address.Pays)!, {
        emitEvent: false
      });
    } else if (this.addressType == Selectionne.Selection) {
      var selected = await lastValueFrom(
        this.store.select(fromAddress.selectAddressSelectionGroupData).pipe(take(1))
      );
      if (selected) {
        this.adresseDataGouvService.search(selected!.Adresse, 1).subscribe({
          next: res => {
            var filtered = res.features.map(feature => {
              const properties = feature.properties;
              return {
                place: `${properties.name}, ${properties.postcode} ${properties.city}, France`,
                id: properties.id,
                postalCode: properties.postcode,
                country: 'FR',
                city: properties.city,
                streetNumber: properties.housenumber,
                street: properties.street
              };
            });
            this.filteredAdresses = filtered;
            var currentAddress = filtered.find(item => selected?.Adresse === item.place);
            if (currentAddress != null && currentAddress.place !== '') {
              this.adresseAutocomplete.patchValue(currentAddress, { emitEvent: false });
            }
          }
        });
      }
    }
  }

  public onNotFoundAddressClick() {
    this.store.dispatch(fromAddress.patchSelectedAddress({ adresseType: Selectionne.Manuel }));
    this.tracker.trackEvent(MatomoConstants.ADDRESS, MatomoConstants.MANUAL_ADDRESS);
    this.isAutocomplete = false;
    this.errorMessageApi = this.resource.error.addressFreeText;
  }

  async filterAddress($event: any) {
    this.adresseDataGouvService.search($event.query, 1).subscribe({
      next: res => {
        if (res) {
          var filtered = res.features.map(feature => {
            const properties = feature.properties;
            return {
              place: `${properties.name}, ${properties.postcode} ${properties.city}, France`,
              id: properties.id,
              postalCode: properties.postcode,
              country: 'FR',
              city: properties.city,
              streetNumber: properties.housenumber,
              street: properties.street
            };
          });
          this.filteredAdresses = filtered;
        }
      }
    });
  }

  async filterCountries($event: any) {
    let query = $event.query;
    if (query != null) {
      var trimmedQuery = query
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLocaleLowerCase('fr-FR');
      this.filteredCountries = this.countries.filter(item => {
        return item.countryNameFr
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLocaleLowerCase('fr-FR')
          .includes(trimmedQuery);
      });
    } else {
      this.filteredCountries = this.countries;
    }
  }

  checkPostalCode() {
    const postalCode = this.isAutocomplete
      ? (this.adresseAutocomplete?.value as Place).postalCode
      : this.postalCode?.value;

    metropolePathSwitch(postalCode, this.store, this.tracker);
  }

  async valider() {
    this.isLoading = true;

    // Retrieve context
    const stateContext = await lastValueFrom(
      this.store.select(fromContext.selectContextId).pipe(take(1))
    );

    let body = {
      Context: stateContext
    } as VyVTarifCheckAddressRequest;

    if (this.isAutocomplete) {
      body.NumeroEtRue = null;
      body.AdresseComplete = (this.adresseAutocomplete?.value as Place).place!;
      body.NumeroDeRue = (this.adresseAutocomplete?.value as Place).streetNumber!;
      body.NomDeRue = (this.adresseAutocomplete?.value as Place).street!;
      body.CodePostal = (this.adresseAutocomplete?.value as Place).postalCode!;
      body.Ville = (this.adresseAutocomplete?.value as Place).city!;
      body.CodePays = (this.adresseAutocomplete?.value as Place).country!;
    } else {
      body.NumeroEtRue = this.address?.value;
      body.CodePostal = this.postalCode?.value;
      body.NumeroDeRue = null;
      body.NomDeRue = null;
      body.Ville = this.city?.value;
      body.Pays = this.country?.value.countryNameFr;
    }

    this.eligibilityService
      .checkAddressEligibility(body, DistributorService.GetDistributorCode())
      .subscribe({
        next: async res => {
          this.store.dispatch(
            fromAddress.changeZIEligibleAHPStatus({
              isZoneInondable: res.floodZone
            })
          );

          // Sauvegarde du stepper
          await this.updateStepper(false);
          // Sauvegarde de l'étape
          await this.updateResumeStep();

          this.checkPostalCode();
          // Redirection vers l'étape suivante
          this.router.navigate([`${ROOT_PATH}${HOUSE_APARTMENT_PATH}`]);
        },
        error: () => {
          this.displayError = true;
          this.isLoading = false;
        },
        complete: () => (this.isLoading = false)
      });
  }

  async updateStepper(init: boolean) {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.lodging
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.address
    );

    if (init) {
      if (subtitleS) {
        subtitleS.isActive = true;
        subtitleS.isVisible = true;
      }
    } else {
      if (subtitleS) subtitleS.isValid = true;

      let subtitleN = title?.subtitles.find(
        (x: { url: string }) => x.url == this.resource.stepper.houseapartment
      );

      if (subtitleN) subtitleN.isActive = true;
    }

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  async updateResumeStep() {
    const resumeStep = await lastValueFrom(
      this.store.select(fromInfo.selectCurrentResumeStep).pipe(take(1))
    );
    const tmpResumeStep = cloneResumeStep(resumeStep);

    let stepResumeStep = tmpResumeStep.steps.find(
      (x: { id: string }) => x.id == this.resource.resumestep.address
    );
    let libelle = '';

    if (this.isAutocomplete) libelle = this.adresseAutocomplete?.value.place;
    else libelle = this.address?.value + ' ' + this.postalCode?.value + ' ' + this.city?.value;

    if (stepResumeStep) {
      stepResumeStep.step = libelle;
      stepResumeStep.isVisible = true;
    } else {
      tmpResumeStep.steps.push({
        id: this.resource.resumestep.address,
        step: libelle,
        url: this.resource.resumestep.address,
        isVisible: true
      });
    }
    this.store.dispatch(fromInfo.updateResumeStep({ payload: tmpResumeStep }));
  }

  cancel() {
    this.displayModal = false;
  }
}
