import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { PolicyOverviewResponse } from 'src/app/api/models/policyOverviewResponse.model';
import { PoliciesService } from 'src/app/api/services/policies.service';
import * as fromPolicy from 'src/app/core/state/contract';
import {
  CONFIRM_CONTRACT_PATH,
  ELECTRONIC_SIGNATURE_PATH,
  ROOT_PATH
} from 'src/app/shared/constants/route.constants';
import * as fromRoot from '../../core/state/core.state';
import * as fromDevis from '../../core/state/devis';
import * as fromPayment from '../../core/state/payment';
import * as fromSubscriber from '../../core/state/subscriber';

@Injectable({
  providedIn: 'root'
})
export class PolicyOverviewService {
  constructor(
    private policyService: PoliciesService,
    private store: Store<fromRoot.State>,
    private router: Router
  ) {}

  public processPolicyOverview(
    policyReference: string,
    contextId: string,
    email: string,
    estSigne: boolean | undefined
  ) {
    this.policyService
      .GetPolicyOverview(policyReference, Guid.parse(contextId).toJSON().value, email)
      .subscribe(data => {
        this.dispatchPolicy(data);
        this.dispatchPayment(data);
        this.dispatchSubscripber(data);
        this.dispatchDevis(data);

        if (estSigne) {
          this.router.navigate([`${ROOT_PATH}${CONFIRM_CONTRACT_PATH}`]);
        } else {
          this.router.navigate([`${ROOT_PATH}${ELECTRONIC_SIGNATURE_PATH}`]);
        }
      });
  }

  private dispatchPolicy(dataPolicy: PolicyOverviewResponse) {
    this.store.dispatch(
      fromPolicy.patchContract({
        payload: {
          RefContrat: dataPolicy.reference,
          NumeroContrat: dataPolicy.agreementId.toString()
        }
      })
    );
  }

  private dispatchSubscripber(dataPolicy: PolicyOverviewResponse) {
    this.store.dispatch(
      fromSubscriber.patchSubscriber({
        payload: {
          Email: dataPolicy.memberEmail
        }
      })
    );
  }

  private dispatchPayment(dataPolicy: PolicyOverviewResponse) {
    this.store.dispatch(
      fromPayment.patchPayment({
        payload: {
          PrefixeTel: dataPolicy.memberPhonePrefix,
          Telephone: dataPolicy.memberPhoneNumber,
          PaymentPrefixTel: dataPolicy.memberPaymentPhonePrefix,
          PaymentTelephone: dataPolicy.memberPaymentPhoneNumber
        }
      })
    );
  }

  private dispatchDevis(dataPolicy: PolicyOverviewResponse) {
    this.store.dispatch(
      fromDevis.patchDevis({
        payload: {
          AgreementId: dataPolicy.agreementId,
          DevisId: dataPolicy.quotationId
        }
      })
    );
  }
}
