import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { lastValueFrom, take } from 'rxjs';
import * as fromContext from 'src/app/core/state/context';
import { State } from 'src/app/core/state/core.state';
import * as fromSignature from 'src/app/core/state/electronic-signature';
import * as fromPayment from 'src/app/core/state/payment';
import { CANAL_VENTE_C } from '../constants/context.constants';
import {
  CONFIRM_CONTRACT_PATH,
  ELECTRONIC_SIGNATURE_PATH,
  ROOT_PATH
} from '../constants/route.constants';

@Injectable({
  providedIn: 'root'
})
export class SignatureGuard {
  constructor(private store: Store<State>, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var isPaymentValid = await (
      await lastValueFrom(this.store.select(fromPayment.selectPayment).pipe(take(1)))
    ).isValid;

    var isSignatureValid = await (
      await lastValueFrom(this.store.select(fromSignature.selectElectronicSignature).pipe(take(1)))
    ).isValid;

    const stateContextData = await lastValueFrom(
      this.store.select(fromContext.selectContextData).pipe(take(1))
    );

    if (isSignatureValid) {
      if (!state.url.includes(`${ROOT_PATH}${CONFIRM_CONTRACT_PATH}`)) {
        this.router.navigate([`${ROOT_PATH}${CONFIRM_CONTRACT_PATH}`]);
        return false;
      }
      return true;
    }
    if (isPaymentValid && !state.url.includes(`${ROOT_PATH}${ELECTRONIC_SIGNATURE_PATH}`)) {
      if (stateContextData?.canalVente !== CANAL_VENTE_C)
        this.router.navigate([`${ROOT_PATH}${ELECTRONIC_SIGNATURE_PATH}`]);
      return false;
    } else {
      return true;
    }
  }

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var isPaymentValid = await (
      await lastValueFrom(this.store.select(fromPayment.selectPayment).pipe(take(1)))
    ).isValid;

    var isSignatureValid = await (
      await lastValueFrom(this.store.select(fromSignature.selectElectronicSignature).pipe(take(1)))
    ).isValid;

    if (isSignatureValid) {
      if (!state.url.includes(`${ROOT_PATH}${CONFIRM_CONTRACT_PATH}`)) {
        this.router.navigate([`${ROOT_PATH}${CONFIRM_CONTRACT_PATH}`]);
        return false;
      }
      return true;
    }
    if (isPaymentValid && !state.url.includes(`${ROOT_PATH}${ELECTRONIC_SIGNATURE_PATH}`)) {
      this.router.navigate([`${ROOT_PATH}${ELECTRONIC_SIGNATURE_PATH}`]);
      return false;
    } else {
      return true;
    }
  }
}
